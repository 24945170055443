import BasicChart from "./basic-chart";

export default function Revenue() {
  return (
    <BasicChart
      url="/operation/get-token-spend-earn-report"
      showResources
      chartType="line"
      showDetail
      value={{ totalOnly: true }}
    />
  );
}
