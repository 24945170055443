/* eslint-disable no-restricted-globals */
import ConstConfig from "config";
import { SetStateAction, useEffect, useState } from "react";
import { Button, Icon, Input, Select, TextArea } from "semantic-ui-react";
import api from '../services/api';
import Config from "./Config";
import crypto from "crypto";
export default function Cheat() {
    const [wallet_address, setWallet] = useState("");
    const [plot_race, setPlotRace] = useState(0);
    const [plot_rarity, setPlotRarity] = useState(0);
    const [mongen_race, setMongenRace] = useState(0);
    const [mongen_rarity, setMongenRarity] = useState(0);
    const [plot_type, setPlotType] = useState(0);
    const [amount, setAmount] = useState(0);
    const [transactionType, setTransactionType] = useState(0);
    const [skiptime, setSkiptime] = useState(0);
    const [plot_id_skiptime, setPlotIdSkiptime] = useState(0);
    const [troop_id, setTroopId] = useState(0);
    const [mongen_id, setMongenId] = useState(0);
    const [cv_mongen_id, setCVMongenId] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [process, setProcess] = useState(0);
    const [level, setLevel] = useState(0);
    const [adventureStar, setAdventureStar] = useState(0);
    const [adventureStarReward, setAdventureStarReward] = useState(0);
    const [time, setTime] = useState(0);
    const [whitelistAddress, setWhitelistAddress] = useState("");
    const [whitelistType, setWhitelistType] = useState(0);
    const [pool, setPool] = useState([]);
    const [pool_id, setPoolId] = useState(1);
    const [mongenInfo, setMongenInfo] = useState<any>();
    const [typeSkill, setTypeSkill] = useState("")
    const troops: any = [];
    const [inputData, setInputData] = useState("");
    const [outputData, setOutputData] = useState("");
    const [isCopied, setCopied] = useState(false);

    const IV_SIZE = 16;
    const encrypt = (plainText: any, keyString: any) => {
        const iv = crypto.randomBytes(IV_SIZE);
        const cipher = crypto.createCipheriv("aes-256-cbc", keyString, iv);
        let cipherText = cipher.update(Buffer.from(plainText, "utf8"));
        cipherText = Buffer.concat([cipherText, cipher.final()]);
        const combinedData = Buffer.concat([iv, cipherText]);
        const combinedString = combinedData.toString("base64");
        return combinedString;
    };

    const decrypt = (combinedString: any, keyString: any) => {
        const combinedData = Buffer.from(combinedString, "base64");
        const iv = Buffer.alloc(IV_SIZE);
        const cipherText = Buffer.alloc(combinedData.length - iv.length);
        combinedData.copy(iv, 0, 0, iv.length);
        combinedData.copy(cipherText, 0, iv.length);
        const decipher = crypto.createDecipheriv("aes-256-cbc", keyString, iv);
        //@ts-ignore
        let plainText = decipher.update(cipherText, "utf8");
        //@ts-ignore
        plainText += decipher.final("utf8");
        return plainText;
    };

    const encryptInput = () => {
        let encrypted = encrypt(
            inputData,
            ConstConfig.EncruptedKey
        );
        setOutputData(encrypted);
    };

    const decryptInput = () => {
        let decrypted = decrypt(
            inputData,
            ConstConfig.EncruptedKey
        );
        setOutputData(decrypted);
    };


    useEffect(() => {
        api.getPool().then((data: SetStateAction<any[]>) => setPool(data));
    }, []);



    async function hackCreatePlot() {
        api
            .hackCreatePlot(wallet_address, plot_race, plot_rarity, plot_type, amount)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }
    async function hackAdventureStar() {
        api
            .cheatAdventureStar(wallet_address, adventureStar)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }
    async function hackAdventureStarReward() {
        api
            .cheatAdventureStarReward(wallet_address, adventureStarReward)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }
    async function hackTutorialProcess() {
        api
            .hackTutorialProcess(wallet_address, process)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }
    async function deleteName() {
        api
            .deleteName(wallet_address)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }
    async function outBattle() {
        api
            .outBattle(wallet_address)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }
    async function hackCreateEgg() {
        api
            .hackCreateEgg(wallet_address, mongen_race, mongen_rarity, amount)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }
    async function hackSkiptime() {
        api
            .hackSkiptime(wallet_address, plot_id_skiptime, skiptime)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }

    async function hackRecalculateStake() {
        const isCf = confirm("Bạn có chắc không? Chắc thật không? Hàm này ảnh hưởng toàn hệ thống đó");
        if (isCf) {
            api
                .recalculateStake()
                .then(() => {
                    alert("success");
                })
                .catch((err: any) => {
                    alert("failed");
                });
        }
    }

    async function hackCustomerTransaction() {
        api
            .hackCustomerTransaction(wallet_address, amount, transactionType)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }


    async function hackDeleteCustomer() {
        const isCf = confirm("Bạn có chắc không? Chắc thật không? Hàm này sẽ xóa luôn account đó");
        if (isCf)
            api
                .hackDeleteCustomer(wallet_address)
                .then(() => {
                    alert("success");
                })
                .catch((err: any) => {
                    alert("failed");
                });
    }

    async function hackCreateTroops() {
        api
            .hackCreateTroops(troop_id, mongen_id, quantity)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }

    async function convertMongenToEgg() {
        api
            .convertMongenToEgg(cv_mongen_id)
            .then(() => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }

    async function hackClaimLevelReward() {
        api
            .cheatClaimLevelReward(wallet_address, level)
            .then((data: any) => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }

    async function hackTimeRequest() {
        // eslint-disable-next-line no-restricted-globals
        const isCf = confirm("Bạn có chắc không? Chắc thật không? Hàm này ảnh hưởng toàn hệ thống đó");
        if (isCf) {
            api
                .cheatSetQuestTime(wallet_address, time)
                .then((data: any) => {
                    alert("success");
                })
                .catch((err: any) => {
                    alert("failed");
                });
        }

    }

    async function hackTimeFaucet() {
        const isCf = confirm("Bạn có chắc không? Chắc thật không? Hàm này ảnh hưởng toàn hệ thống đó");
        if (isCf)
            api
                .cheatSetFaucetTime(time)
                .then((data: any) => {
                    alert("success");
                })
                .catch((err: any) => {
                    alert("failed");
                });
    }
    function addWhiteList() {
        api
            .addWhitelist(whitelistType, whitelistAddress)
            .then((data: any) => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }
    function hackCreateBox() {
        api
            .cheatCreateBox(wallet_address, pool_id)
            .then((data: any) => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }

    async function hackUpdateTroopMongen() {
        api
            .updateTroopMongen(mongen_id, mongenInfo.U)
            .then((data: any) => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }
    async function hackUpdateSkillMongen() {
        if (!typeSkill) return;
        let skill_list = [];
        if (typeSkill === 'basic') skill_list = mongenInfo.R;
        if (typeSkill === 'ultimate') skill_list = mongenInfo.S
        skill_list = skill_list.map((skill: string) => parseInt(skill))
        api
            .updateSkillMongen(mongen_id, skill_list, typeSkill)
            .then((data: any) => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }

    async function hackgenerateInvest() {
        api
            .hackgenerateInvest()
            .then((data: any) => {
                alert("success");
            })
            .catch((err: any) => {
                alert("failed");
            });
    }

    return (
        <div className="w-100">
            <div className="w-full text-3xl text-center">Holy cheat</div>
            <div className="card w-full">
                <div className="card-body">
                    <Input
                        label="wallet address"
                        className="form-control"
                        type="text"
                        onChange={(evt: any) => {
                            setWallet(evt.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="card-body">
                <div className="card-title">Add whitelist</div>
                <div className="d-flex w-full">
                    <div className="row">
                        <div className="flex gap-4 flex gap-4">
                            <Input
                                label="Wallet address/Email"
                                className="form-control"
                                onChange={(evt: any) => {
                                    setWhitelistAddress(evt.target.value);
                                }}
                            />
                            <Select
                                className="form-control"
                                onChange={(evt: any, data: any) => {
                                    setWhitelistType(Number(data.value));
                                }}
                                options={
                                    [
                                        {
                                            text: "Metamask",
                                            value: 0,
                                        },
                                        {
                                            text: "Terra",
                                            value: 1,
                                        },
                                        {
                                            text: "Email",
                                            value: 2,
                                        },
                                    ]
                                }
                            >
                            </Select>
                            <Button color="green" className="btn btn-primary btn-lg" onClick={addWhiteList}>
                                Add
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="card-title">Hack generate invests</div>
                <div className="d-flex w-full">
                    <div className="row">
                        <div className="flex gap-4">
                            <div className="mt-3">
                                <Button color="green" onClick={hackgenerateInvest}>
                                    Generate
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="card-title">Hack time request</div>
                <div className="d-flex w-full">
                    <div className="row">
                        <div className="flex gap-4">
                            <Input
                                label="Time (after ? seconds)"
                                className="form-control"
                                type="number"
                                onChange={(evt: any) => {
                                    setTime(evt.target.value);
                                }}
                            />
                            <Button color="red" className="btn btn-primary btn-lg" onClick={hackTimeRequest}>
                                Hack
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="card-title">Hack time faucet</div>
                <div className="d-flex w-full">
                    <div className="row">
                        <div className="flex gap-4">
                            <Input
                                label="Time (after ? seconds)"
                                className="form-control"
                                type="number"
                                onChange={(evt: any) => {
                                    setTime(evt.target.value);
                                }}
                            />
                            <Button color="red" className="btn btn-primary btn-lg" onClick={hackTimeFaucet}>
                                Hack
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="card-title">Hack claim level reward</div>
                <div className="d-flex w-full">
                    <div className="flex gap-4">
                        <Input
                            label="Level want to claim"
                            className="form-control"
                            type="text"
                            onChange={(evt: any) => {
                                setLevel(evt.target.value);
                            }}
                        />
                        <Button color="green" className="btn btn-primary btn-lg" onClick={hackClaimLevelReward}>
                            Hack
                        </Button>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="card-title">Hack set adventure star</div>
                <div className="d-flex w-full">
                    <div className="row">
                        <div className="flex gap-4">
                            <Input
                                label="Star"
                                className="form-control"
                                type="text"
                                onChange={(evt: any) => {
                                    setAdventureStar(evt.target.value);
                                }}
                            />
                            <Button color="green" className="btn btn-primary btn-lg" onClick={hackAdventureStar}>
                                Hack
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="card-title">Hack set adventure star reward</div>
                <div className="d-flex w-full">
                    <div className="row">
                        <div className="flex gap-4">
                            <Input
                                label="Adventure star reward"
                                className="form-control"
                                type="text"
                                onChange={(evt: any) => {
                                    setAdventureStarReward(evt.target.value);
                                }}
                            />
                            <Button color="green" className="btn btn-primary btn-lg" onClick={hackAdventureStarReward}>
                                Hack
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="card-title">Hack stake</div>
                <div className="d-flex w-full">
                    <Button color="yellow" className="btn btn-outline-primary btn-lg" onClick={hackRecalculateStake}>
                        Skip section
                    </Button>
                </div>
            </div>
            <div className="card w-full">
                <div className="card-body">
                    <div className="card-title">Hack create plot</div>
                    <div className="d-flex w-full">
                        <div className="flex gap-4">
                            <Select
                                className="form-control"
                                onChange={(evt: any, data: any) => {
                                    setPlotRace(data.value);
                                }}
                                options={Config.Race.map((value: any, index: number) => {
                                    return {
                                        text: value,
                                        value: index
                                    }
                                })}
                            />
                            <Select
                                className="form-control"
                                onChange={(evt: any, data: any) => {
                                    setPlotRarity(data.value);
                                }}
                                options={Config.Rarity.map((value: any, index: number) => {
                                    return {
                                        text: value,
                                        value: index
                                    }
                                })}
                            />
                            <Select
                                className="form-control"
                                onChange={(evt: any, data: any) => {
                                    setPlotType(data.value);
                                }}
                                options={Config.PlotType.map((value: any, index: number) => {
                                    return {
                                        text: value,
                                        value: index
                                    }
                                })}
                            />
                            <Input
                                label="amount"
                                className="form-control"
                                type="text"
                                onChange={(evt: any) => {
                                    setAmount(evt.target.value);
                                }}
                            />
                            <Button color="green" className="btn btn-primary btn-lg" onClick={hackCreatePlot}>
                                Hack
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="card-title">Hack create box</div>
                    <div className="flex gap-4">
                        <Select
                            className="form-control"
                            onChange={(evt: any, data: any) => {
                                setPoolId(data.value);
                            }}
                            options={pool.map((value: any, index: number) => {
                                return {
                                    text: value.id,
                                    value: value.name
                                }
                            })}
                        />
                        <Button color="green" className="btn btn-primary btn-lg" onClick={hackCreateBox}>
                            Hack
                        </Button>
                    </div>
                </div>

                <div className="card-body">
                    <div className="card-title">Hack create mongens</div>
                    <div className="d-flex w-full">
                        <div className="flex gap-4">
                            <Select
                                className="form-control"
                                onChange={(evt: any, data: any) => {
                                    setMongenRace(data.value);
                                }}
                                options={Config.Race.map((value: any, index: number) => {
                                    return {
                                        text: value,
                                        value: index
                                    }
                                })}
                            />
                            <Select
                                className="form-control"
                                onChange={(evt: any, data: any) => {
                                    setMongenRarity(data.value);
                                }}
                                options={Config.Rarity.map((value: any, index: number) => {
                                    return {
                                        text: value,
                                        value: index
                                    }
                                })}
                            />
                            <Input
                                label="amount"
                                className="form-control"
                                type="text"
                                onChange={(evt: any) => {
                                    setAmount(evt.target.value);
                                }}
                            />
                            <Button color="green" className="btn btn-primary btn-lg" onClick={hackCreateEgg}>
                                Hack
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="card-title">Hack convert mongen to egg</div>
                    <div className="d-flex w-full">
                        <div className="row">
                            <div className="flex gap-4">
                                <Input
                                    label="mongen_id"
                                    className="form-control"
                                    onChange={(evt: any) => {
                                        setCVMongenId(evt.target.value);
                                    }}
                                />
                                <Button color="green" className="btn btn-primary btn-lg" onClick={convertMongenToEgg}>
                                    Hack
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="card-title">Hack customer transaction</div>
                    <div className="d-flex w-full">
                        <div className="flex gap-4">
                            <Input
                                label="Amount"
                                className="form-control"
                                type="text"
                                onChange={(evt: any) => {
                                    setAmount(evt.target.value);
                                }}
                            />
                            <Select
                                className="form-control"
                                onChange={(evt: any, data: any) => {
                                    setTransactionType(data.value);
                                }}
                                options={
                                    Config.CustomerResources.map((value: any, index: number) => {
                                        return {
                                            text: value,
                                            value: index,
                                        }
                                    })
                                }
                            />
                            <Button color="green" className="btn btn-primary btn-lg" onClick={hackCustomerTransaction}>
                                Hack
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="card-title">Hack tutorial process</div>
                    <div className="d-flex w-full">
                        <div className="row">
                            <div className="flex gap-4">
                                <Input
                                    label="Milestone"
                                    className="form-control"
                                    type="number"
                                    onChange={(evt: any) => {
                                        setProcess(evt.target.value);
                                    }}
                                />
                                <Button color="green" className="btn btn-primary btn-lg" onClick={hackTutorialProcess}>
                                    Hack
                                </Button>
                            </div>
                            <div className="mt-3">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="card-title">Hack delete customer</div>
                    <Button color="red" onClick={hackDeleteCustomer}>
                        Delete
                    </Button>
                </div>
                <div className="card-body">
                    <div className="card-title">Hack delete name</div>
                    <Button color="red" onClick={deleteName}>
                        Delete
                    </Button>
                </div>
                <div className="card-body">
                    <div className="card-title">Hack out battle</div>
                    <Button color="green" className="btn btn-danger btn-lg" onClick={outBattle}>
                        Out Battle
                    </Button>
                </div>
            </div>

            <div className="card w-full">
                <div className="card-title">Hack skiptime</div>
                <div className="card-body flex gap-4">
                    <Input
                        label="plot_id"
                        className="form-control"
                        type="text"
                        onChange={(evt: any) => {
                            setPlotIdSkiptime(evt.target.value);
                        }}
                    />
                    <Input
                        label="time"
                        className="form-control"
                        type="number"
                        onChange={(evt: any) => {
                            setSkiptime(Number(evt.target.value));
                        }}
                    />
                    <Button color="green" className="btn btn-primary btn-lg" onClick={hackSkiptime}>
                        Hack
                    </Button>
                </div>
            </div>

            <div className="card w-full">
                <div className="card-body">
                    <div className="card-title">Hack create troops</div>
                    <div className="flex gap-4">
                        <Select
                            className="form-control"
                            onChange={(evt: any, data: any) => {
                                setTroopId(data.value);
                            }}
                            options={
                                troops?.map((value: any, index: number) => {
                                    return {
                                        value: index,
                                        text: value.name
                                    }
                                })
                            }
                        />
                        <Input
                            label="mongen_id"
                            className="form-control"
                            type="number"
                            onChange={(evt: any) => {
                                setMongenId(Number(evt.target.value));
                            }}
                        />
                        <Input
                            label="quantity"
                            className="form-control"
                            type="number"
                            onChange={(evt: any) => {
                                setQuantity(Number(evt.target.value));
                            }}
                        />
                        <Button color="green" className="btn btn-primary btn-lg" onClick={hackCreateTroops}>
                            Hack
                        </Button>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="card-title">Hack update troops</div>
                    <div className="grid grid-rows-4 gap-4">
                        <Input
                            label="mongen_id"
                            className="form-control w-[200px]"
                            type="number"
                            onChange={async (evt: any) => {
                                setMongenId(Number(evt.target.value));
                                await api
                                    .getMongenInfo(Number(evt.target.value))
                                    .then((res: { mongenInfo: (prevState: undefined) => undefined; }) => {
                                        setMongenInfo(res.mongenInfo);
                                    })
                                    .catch((err: any) => {
                                    });
                            }}
                        />
                        {[0, 1, 2].map((index) => {
                            return (
                                <div className="flex gap-4" key={index}>
                                    <Input
                                        label="troop_id"
                                        className="form-control"
                                        type="number"
                                        value={mongenInfo && mongenInfo.U && mongenInfo.U[index] ? mongenInfo.U[index].id : ''}
                                        onChange={(evt: any) => {
                                            const newMongenInfo = JSON.parse(JSON.stringify(mongenInfo));
                                            newMongenInfo.U[index].id = Number(evt.target.value)
                                            setMongenInfo(newMongenInfo);
                                        }}
                                    />
                                    <Input
                                        label="quantity"
                                        className="form-control"
                                        type="number"
                                        value={mongenInfo && mongenInfo.U && mongenInfo.U[index] ? mongenInfo.U[index].quant : ''}
                                        onChange={(evt: any) => {
                                            const newMongenInfo = JSON.parse(JSON.stringify(mongenInfo));
                                            newMongenInfo.U[index].quant = Number(evt.target.value)
                                            setMongenInfo(newMongenInfo);
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <Button color="green" className="w-[200px]" onClick={hackUpdateTroopMongen}>
                            Hack
                        </Button>
                    </div>
                </div>
            </div>

            <div className="card w-full">
                <div className="card-body">
                    <div className="card-title">Hack update skill</div>
                    <div className="flex gap-4">
                        <Input
                            label="mongen_id"
                            className="form-control"
                            type="number"
                            onChange={async (evt: any) => {
                                setMongenId(Number(evt.target.value));
                                await api
                                    .getMongenInfo(Number(evt.target.value))
                                    .then((res: { mongenInfo: (prevState: undefined) => undefined; }) => {
                                        setMongenInfo(res.mongenInfo);
                                    })
                                    .catch((err: any) => {
                                    });
                            }}
                        />
                        <Select
                            className="form-control"
                            onChange={(evt: any, data: any) => {
                                setTypeSkill(data.value);
                            }}
                            options={[
                                {
                                    text: "Basic skill", value: "basic"
                                },
                                {
                                    text: "Ultimate skill", value: "ultimate"
                                }
                            ]}
                        />
                        <Input
                            label="skill"
                            className="form-control"
                            type="text"
                            value={typeSkill === 'basic' && mongenInfo && mongenInfo.R ? mongenInfo.R.join(',') :
                                typeSkill === 'ultimate' && mongenInfo && mongenInfo.S ? mongenInfo.S.join(',') : ''}
                            onChange={(evt: any) => {
                                const newMongenInfo = JSON.parse(JSON.stringify(mongenInfo));
                                if (typeSkill === 'basic') newMongenInfo.R = evt.target.value.split(',')
                                if (typeSkill === 'ultimate') newMongenInfo.S = evt.target.value.split(',')
                                setMongenInfo(newMongenInfo);
                            }}
                        />
                        <Button color="green" className="btn btn-primary btn-lg" onClick={hackUpdateSkillMongen}>
                            Hack
                        </Button>
                    </div>
                </div>
            </div>

            <div className=" w-full mt-10 mb-10">
                <h3>Encrypt</h3>
                <div className="card w-full">
                    <div className="card-body">
                        <TextArea
                            className="w-full"
                            style={{ border: "1px solid black" }}
                            label="input"
                            rows={6}
                            type="text"
                            value={inputData}
                            onChange={(e) => setInputData(e.target.value)}
                        />
                    </div>
                    <div className="card-body">
                        <Button color="blue" className="btn btn-primary btn-lg" onClick={encryptInput}>
                            Encrypt
                        </Button>
                        <Button color="green" className="btn btn-primary btn-lg m-3" onClick={decryptInput}>
                            Decrypt
                        </Button>
                    </div>
                    <div className="flex gap-4 mt-10">
                        <div>Output</div>
                        <div className="cursor-pointer" onClick={() => {
                            navigator.clipboard.writeText(outputData);
                            setCopied(true);
                            setTimeout(() => {
                                setCopied(false);
                            }, 2000)
                        }}>
                            <Icon name={isCopied ? "check" : "copy"} size="small" />
                        </div>
                    </div>
                    <div className="w-full h-24 mt-2"
                        style={{
                            border: "1px solid black"
                        }}
                    >
                        <code className="mx-auto" >
                            {outputData}
                        </code>
                    </div>
                </div>
            </div>
        </div >
    );
}
