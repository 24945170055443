import skyImage from '../assets/sky.jpg';
import { useEffect, useMemo, useState } from "react";
import api from "../services/api";
import Config from "./Config";
const WIDTH = 20,
    HEIGHT = 20;
const Rarity = { Common: 0, Uncommon: 1, Rare: 2, Epic: 3, Legend: 4 };
const PlotTypes = [
    {
        name: "Landcore",
        rarity: Rarity.Common,
        img: "Landcore/Core_1.png",
        size: [2, 2],
        type: 0,
        race: 5,
    },
    {
        name: "Landcore",
        rarity: Rarity.Uncommon,
        img: "Landcore/Core_2.png",
        size: [2, 2],
        type: 0,
        race: 5,
    },
    {
        name: "Landcore",
        rarity: Rarity.Rare,
        img: "Landcore/Core_3.png",
        size: [2, 2],
        type: 0,
        race: 5,
    },
    {
        name: "Hatching",
        rarity: Rarity.Common,
        img: "Hatching/Hatch_1.png",
        size: [2, 2],
        type: 3,
        race: 5,
    },
    {
        name: "Hatching",
        rarity: Rarity.Uncommon,
        img: "Hatching/Hatch_2.png",
        size: [2, 2],
        type: 3,
        race: 5,
    },
    {
        name: "Hatching",
        rarity: Rarity.Rare,
        img: "Hatching/Hatch_3.png",
        size: [2, 2],
        type: 3,
        race: 5,
    },
    {
        name: "Breeding",
        rarity: Rarity.Common,
        img: "Breed/Breed_1.png",
        size: [2, 2],
        type: 2,
        race: 5,
    },
    {
        name: "Breeding",
        rarity: Rarity.Uncommon,
        img: "Breed/Breed_2.png",
        size: [2, 2],
        type: 2,
        race: 5,
    },
    {
        name: "Breeding",
        rarity: Rarity.Rare,
        img: "Breed/Breed_3.png",
        size: [2, 2],
        type: 2,
        race: 5,
    },
    {
        name: "Production",
        rarity: Rarity.Common,
        img: "Production/Prodcution_1.png",
        size: [1, 1],
        type: 4,
        race: 5,
    },
    {
        name: "Production",
        rarity: Rarity.Uncommon,
        img: "Production/Production_2.png",
        size: [2, 1],
        type: 4,
        race: 5,
    },
    {
        name: "Production",
        rarity: Rarity.Rare,
        img: "Production/Production_3.png",
        size: [2, 2],
        type: 4,
        race: 5,
    },
    {
        name: "Storage",
        rarity: Rarity.Common,
        img: "Storage/Storage_1.png",
        size: [2, 2],
        type: 5,
        race: 5,
    },
    {
        name: "Storage",
        rarity: Rarity.Uncommon,
        img: "Storage/Storage_2.png",
        size: [2, 2],
        type: 5,
        race: 5,
    },
    {
        name: "Storage",
        rarity: Rarity.Rare,
        img: "Storage/Storage_3.png",
        size: [2, 2],
        type: 5,
        race: 5,
    },
    {
        name: "Pasture Beast",
        rarity: Rarity.Common,
        img: "Beast/Beast_1.png",
        size: [1, 1],
        type: 1,
        race: 0,
    },
    {
        name: "Pasture Beast",
        rarity: Rarity.Uncommon,
        img: "Beast/Beast_2.png",
        size: [2, 1],
        type: 1,
        race: 0,
    },
    {
        name: "Pasture Beast",
        rarity: Rarity.Rare,
        img: "Beast/Beast_3.png",
        size: [2, 2],
        type: 1,
        race: 0,
    },
    {
        name: "Pasture Mystic",
        rarity: Rarity.Common,
        img: "Mystic/Mystic_1.png",
        size: [1, 1],
        type: 1,
        race: 2,
    },
    {
        name: "Pasture Mystic",
        rarity: Rarity.Uncommon,
        img: "Mystic/Mystic_2.png",
        size: [2, 1],
        type: 1,
        race: 2,
    },
    {
        name: "Pasture Mystic",
        rarity: Rarity.Rare,
        img: "Mystic/Mystic_3.png",
        size: [2, 2],
        type: 1,
        race: 2,
    },
    {
        name: "Pasture Tectos",
        rarity: Rarity.Common,
        img: "Tectos/Tectos_1.png",
        size: [1, 1],
        type: 1,
        race: 1,
    },
    {
        name: "Pasture Tectos",
        rarity: Rarity.Uncommon,
        img: "Tectos/Tectos_2.png",
        size: [2, 1],
        type: 1,
        race: 1,
    },
    {
        name: "Pasture Tectos",
        rarity: Rarity.Rare,
        img: "Tectos/Tectos_3.png",
        size: [2, 2],
        type: 1,
        race: 1,
    },
];
export default function InitData() {
    const [allBase, setAllBase] = useState([]);
    const [activeLand, setActiveLand] = useState(null);
    const [eggs, setEggs] = useState([]);
    const [activeEgg, setActiveEgg] = useState(null);
    const [resources, setResources] = useState<any>({});
    const map = useMemo(() => {
        if (!activeLand) return null;
        let mp: any[] = [];
        for (var i = 0; i < WIDTH; i++) {
            mp[i] = [];
            for (var j = 0; j < HEIGHT; j++) {
                mp[i].push({ i, j, x: j - WIDTH / 2, y: HEIGHT / 2 - i });
            }
        }
        activeLand.plots.forEach((e: any) => {
            let pl = PlotTypes.find((i) => {
                if (e.plot_type === 1) {
                    return (
                        i.rarity === e.rarity &&
                        i.type === e.plot_type &&
                        e.plot_race === i.race
                    );
                }
                return i.rarity === e.rarity && i.type === e.plot_type;
            });
            if (pl) {
                mp[10 - e.y][e.x + 10].plot = pl;
            }
        });
        return mp;
    }, [activeLand]);
    useEffect(() => {
        if (!activeLand) {
            return;
        }
        setEggs(activeLand.eggs || []);
        setResources(activeLand.resources || {});
    }, [activeLand]);
    async function getListLandBase() {
        let rs = await api.post("/land/get-list-land-base", {});
        setAllBase(rs);
        setEggs(rs.eggs || []);
    }
    useEffect(() => {
        getListLandBase();
    }, []);
    const [selectTile, setSelectTile] = useState(null);
    function createNew() {
        setActiveLand({ plots: [] });
    }
    async function deleteLandBase() {
        try {
            await api.post("/land/delete-land-base", {
                id: activeLand?.id,
            });
            getListLandBase();
            setActiveLand(null);
            alert("success");
        } catch (error: any) {
            alert(error.msg);
        }
    }
    async function save() {
        try {
            let plots = [];
            for (var i = 0; i < map.length; i++) {
                for (var j = 0; j < map[i].length; j++) {
                    let tile = map[i][j];
                    if (tile.plot) {
                        plots.push({
                            x: tile.x,
                            y: tile.y,
                            rarity: tile.plot.rarity,
                            plot_type: tile.plot.type,
                            plot_race: tile.plot.race,
                        });
                    }
                }
            }
            let rs = await api.post("/land/create-land-base", {
                id: activeLand?.id,
                plots,
                eggs,
                resources,
            });
            getListLandBase();
            alert("success");
        } catch (error: any) {
            alert(error.msg);
        }
    }
    return (
        <div className="bootstrap-iso">
            <SelectPlotModal
                show={selectTile !== null}
                tile={selectTile}
                onSelect={(type: any) => {
                    setSelectTile(null);
                    selectTile.plot = type;
                }}
                onClose={() => {
                    setSelectTile(null);
                }}
            />
            <div>
                <p>Land base</p>
                {allBase.map((i) => (
                    <div
                        className="p-1 d-inline-block"
                        onClick={() => {
                            setActiveLand(i);
                        }}
                    >
                        <div
                            className={`hover-border ${activeLand?.id === i.id && "active"
                                } d-inline-block p-2`}
                        >
                            <b>
                                <p>id: {i.id}</p>
                            </b>
                        </div>
                    </div>
                ))}
                <button
                    className="btn btn-success me-2"
                    onClick={() => {
                        createNew();
                    }}
                >
                    Add land base
                </button>
                <button
                    className="btn btn-primary me-2"
                    onClick={() => {
                        save();
                    }}
                >
                    Save
                </button>
                <button
                    className="btn btn-danger"
                    onClick={() => {
                        deleteLandBase();
                    }}
                >
                    Delete
                </button>
            </div>
            <p>Eggs</p>
            {activeLand && (
                <div>
                    {eggs?.map((i, index) => (
                        <div
                            onClick={() => {
                                setActiveEgg(index);
                            }}
                            className={`d-inline-block hover-border me-2 p-2 ${activeEgg === index && "active"
                                }`}
                        >
                            <p>{Config.Race[i.race]}</p>
                            <p>{Config.Rarity[i.rarity]}</p>
                        </div>
                    ))}
                    <button
                        className="btn btn-success me-2"
                        onClick={() => {
                            let tmp = [...eggs];
                            tmp.push({ rarity: 0, race: 0 });
                            setEggs(tmp);
                        }}
                    >
                        Add eggs
                    </button>
                    <button
                        className="btn btn-danger me-2"
                        onClick={() => {
                            let tmp = [...eggs];
                            tmp.splice(activeEgg, 1);
                            setEggs(tmp);
                        }}
                    >
                        Delete
                    </button>
                    <div className="d-inline-block me-2">
                        <p>Race</p>
                        <select
                            value={eggs[activeEgg]?.race}
                            className="form-control"
                            onChange={(evt) => {
                                let tmp = [...eggs];
                                tmp[activeEgg].race = evt.target.value;
                                setEggs(tmp);
                            }}
                        >
                            {Config.Race.map((i, index) => (
                                <option value={index}>{Config.Race[index]}</option>
                            ))}
                        </select>
                    </div>
                    <div className="d-inline-block me-2">
                        <p>Rarity</p>
                        <select
                            value={eggs[activeEgg]?.rarity}
                            className="form-control"
                            onChange={(evt) => {
                                let tmp = [...eggs];
                                tmp[activeEgg].rarity = evt.target.value;
                                setEggs(tmp);
                            }}
                        >
                            {Config.Rarity.map((i, index) => (
                                <option value={index}>{Config.Rarity[index]}</option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
            <div>
                <p>Resources</p>
                <div className="d-flex">
                    <div className="inline-block me-2">
                        <label>MAG</label>
                        <input
                            value={resources.mag}
                            type="text"
                            className="form-control"
                            onChange={(evt) => {
                                let tmp = Object.assign({}, resources);
                                tmp["mag"] = evt.target.value;
                                setResources(tmp);
                            }}
                        />
                    </div>
                    <div className="inline-block me-2">
                        <label>MSTR</label>
                        <input
                            value={resources.mstr}
                            type="text"
                            className="form-control"
                            onChange={(evt) => {
                                let tmp = Object.assign({}, resources);
                                tmp["mstr"] = evt.target.value;
                                setResources(tmp);
                            }}
                        />
                    </div>
                    <div className="inline-block me-2">
                        <label>Evolution items</label>
                        <input
                            value={resources.evolution_items}
                            type="text"
                            className="form-control"
                            onChange={(evt) => {
                                let tmp = Object.assign({}, resources);
                                tmp["evolution_items"] = evt.target.value;
                                setResources(tmp);
                            }}
                        />
                    </div>
                    <div className="inline-block me-2">
                        <label>Food</label>
                        <input
                            value={resources.food}
                            type="text"
                            className="form-control"
                            onChange={(evt) => {
                                let tmp = Object.assign({}, resources);
                                tmp["food"] = evt.target.value;
                                setResources(tmp);
                            }}
                        />
                    </div>
                </div>
            </div>
            {map && (
                <div
                    className="mt-2"
                    style={{ backgroundImage: `url(${skyImage})`, display: "inline-block" }}
                >
                    <p>Map</p>
                    {map.map((i) => {
                        return (
                            <div className="d-flex">
                                {i.map((j: any) => {
                                    return (
                                        <div
                                            className="map-tile position-relative"
                                            onClick={() => {
                                                setSelectTile(j);
                                            }}
                                        >
                                            {j.plot && (
                                                <div className="relative">
                                                    <img
                                                        alt="grass"
                                                        src={`assets/grass.png`}
                                                        style={{
                                                            position: "absolute",
                                                            zIndex: 1,
                                                            right: 0,
                                                            top: 0,
                                                            width: j.plot.size[0] * 50,
                                                            height: j.plot.size[1] * 50,
                                                            maxWidth: "unset"
                                                        }}
                                                    />
                                                    <img
                                                        alt="map icon"
                                                        src={`/assets/Map Icon/${j.plot.img}`}
                                                        style={{
                                                            position: "absolute",
                                                            zIndex: 2,
                                                            right: 0,
                                                            top: 0,
                                                            objectFit: "contain",
                                                            width: j.plot.size[0] * 50,
                                                            height: j.plot.size[1] * 50,
                                                            maxWidth: "unset"
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

function SelectPlotModal({ show, tile, onClose, onSelect }: any) {
    return (
        <div
            style={{
                display: !show && "none",
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: 10,
                width: "100vw",
                height: "100vh",
            }}
        >
            <div
                style={{
                    width: "100vw",
                    height: "100vh",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#000000a6",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                    }}
                    onClick={onClose}
                ></div>
                <div
                    style={{
                        width: 1000,
                        backgroundColor: "white",
                        zIndex: 10,
                    }}
                    className="p-2"
                >
                    <p>Plot</p>
                    {tile?.plot && (
                        <div>
                            <img src={`assets/Map Icon/${tile?.plot.img}`} alt="map" />
                            <button
                                className="btn btn-danger"
                                onClick={() => {
                                    onSelect(null);
                                }}
                            >
                                Remove
                            </button>
                        </div>
                    )}
                    <p>Select plot</p>
                    {PlotTypes.map((i) => (
                        <div
                            className="p-1 d-inline-block"
                            onClick={() => {
                                onSelect(i);
                            }}
                        >
                            <div
                                className="hover-border"
                                style={{
                                    padding: 2,
                                    display: "inline-block",
                                }}
                            >
                                <img
                                    style={{ objectFit: "contain", width: 100, height: 100 }}
                                    src={`assets/Map Icon/${i.img}`}
                                    alt="map-icon"
                                />
                                <p className="w-100 text-center">
                                    {i.name} [{i.rarity}]
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
