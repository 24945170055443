import { BidEntity, BidType, MongenRace, PlotType, RewardEntity, RewardType } from "interfaces";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "semantic-ui-react";
import { utils } from "services";
import Entity from "./entity";
import Enum from "./enum";

export default function BidEditor({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: BidEntity[];
  onChange: Function;
}) {
  function addReward() {
    let tmp: BidEntity[] = [...value];
    tmp.push({
      type: null,
      quantity: null,
      plot_type: null,
      race: null,
      rarity: null,
      dna: null,
      plot_data: null,
    });
    onChange(tmp);
  }
  function removeReward(index: number) {
    let tmp: BidEntity[] = [...value];
    tmp.splice(index, 1);
    onChange(tmp);
  }
  function handleChange(index: number, name: string, val: any) {
    let tmp: BidEntity[] = [...value];
    //@ts-ignore
    tmp[index][name] = val;
    onChange(tmp);
  }
  return (
    <div>
      {value.map((i: BidEntity, index: number) => (
        <div
          key={index}
          className="flex gap-2 justify-between border hover:border-gray-200 border-white p-1"
        >
          <div className="w-full flex gap-2 items-center">
            <span className="font-semibold mr-2 w-6">{index + 1}.</span>
            <div className="w-44">
              <Enum
                placeholder="Bid item"
                enumName="bid-type"
                value={i.type}
                onChange={(val) => {
                  handleChange(index, "type", Number(val));
                }}
              />
            </div>
            <div className="w-32">
              <Input
                className="w-32"
                value={i.quantity || ""}
                placeholder="Quantity"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(
                    index,
                    "quantity",
                    Number(evt.target.value)
                  );
                }}
              />
            </div>

            {i.type === BidType.PLOT && (
              <div className="w-44">
                <Enum
                  placeholder="Plot type"
                  enumName="plot-type"
                  value={i.plot_type}
                  onChange={(val) => {
                    handleChange(index, "plot_type", Number(val));
                  }}
                  disabledValue={[-1, PlotType.All]}
                />
              </div>
            )}
            {([BidType.PLOT].includes(i.type)) && (
              <div className="w-44">
                <Enum
                  placeholder="Race"
                  enumName="mongen-race"
                  value={i.race}
                  onChange={(val) => {
                    handleChange(index, "race", Number(val));
                  }}
                  disabledValue={
                    i.plot_type !== PlotType.Pasture ?
                      [MongenRace.Beast, MongenRace.Celest, MongenRace.Chaos, MongenRace.Mystic, MongenRace.Tectos, -1] : [MongenRace.All]
                  }
                />
              </div>
            )}
            {([BidType.PLOT].includes(i.type)) && (
              <div className="w-44">
                <Enum
                  placeholder="Rarity"
                  enumName="rarity"
                  value={i.rarity}
                  onChange={(val) => {
                    handleChange(index, "rarity", Number(val));
                  }}
                />
              </div>
            )}
            {([BidType.SOULCORE, BidType.MONGEN].includes(i.type)) && (
              <div className="w-[480px]">
                <Input
                  placeholder="DNA"
                  type="text"
                  value={utils.getStringifyValue(i.dna)}
                  onChange={(val, data) => {
                    handleChange(index, "dna", data.value);
                  }}
                />
              </div>
            )}
            {([BidType.BLUEPRINT].includes(i.type)) && (
              <div className="w-[480px]">
                <Input
                  placeholder="Map data"
                  type="text"
                  value={utils.getStringifyValue(i.plot_data)}
                  onChange={(val, data) => {
                    handleChange(index, "plot_data", data.value);
                  }}
                />
              </div>
            )}
          </div>
          {(!viewOnly && [BidType.SOULCORE, BidType.MONGEN].includes(i.type)) && (
            <Button
              icon="edit"
              color="blue"
              onClick={() => {
                window.open('/#/dna-editor', '_blank')
              }}
            />
          )}
          {(!viewOnly && [BidType.BLUEPRINT].includes(i.type)) && (
            <Button
              icon="edit"
              color="blue"
              onClick={() => {
                window.open('/#/mapdesign', '_blank')
              }}
            />
          )}
          {!viewOnly && (
            <Button
              icon="times"
              color="red"
              onClick={() => {
                removeReward(index);
              }}
            />
          )}
        </div>
      ))}
      <div className="mt-2">
        {!viewOnly && (
          <Button
            icon="plus"
            content="Add"
            labelPosition="left"
            onClick={addReward}
          />
        )}
      </div>
    </div>
  );
}
