import BasicChart from "./basic-chart";

export default function ItemChart(props: any) {
  return (
    <BasicChart
      {...props}
      url="/operation/get-offchain-new-item-report"
      chartType="line"
      showItemType
    />
  );
}
