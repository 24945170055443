import { Routes, Route } from "react-router-dom";
import Profile from "profile";
import Home from "home";
import List from "list";
import Form from "form";
import Chart from "chart";
import Cheat from "cheat/cheat";
import LevelDesign from "cheat/levelDesign";
import MapDesign from "cheat/mapDesign";
import DnaSample from "cheat/dna";
import InitData from "cheat/editLand";
import Setting from "setting";
import TicketDetail from "ticket-detail";
import CustomerInfo from "customer-info";
import SupportStatistic from "support-statistic";
import DNAMaker from "dna";
function Default() {
  return (
    <div className="w-full h-full">
      <p className="text-center text-2xl font-semibold">Monsterra!</p>
    </div>
  );
}
export default function Main({ showMenu }: { showMenu: boolean }) {
  return (
    <div>
      <div className={`p-4 pt-20 duration-200 ${showMenu && "pl-[260px]"}`}>
        <Routes>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/dashboard" element={<Home />}></Route>
          <Route path="/list/:name" element={<List />}></Route>
          <Route path="/form/:name/:mode" element={<Form />}></Route>
          <Route path="/chart/:type" element={<Chart />}></Route>
          <Route path="/cheat" element={<Cheat />}></Route>
          <Route path="/dna-editor" element={<DNAMaker />}></Route>
          <Route path="/leveldesign" element={<LevelDesign />}></Route>
          <Route path="/mapdesign" element={<MapDesign />}></Route>
          <Route path="/dna" element={<DnaSample />}></Route>
          <Route path="/edit-landbase" element={<InitData />}></Route>
          <Route path="/setting/:name" element={<Setting />}></Route>
          <Route path="/ticket-detail" element={<TicketDetail />}></Route>
          <Route path="/customer-info" element={<CustomerInfo />}></Route>
          <Route path="/" element={<Default />}></Route>

          <Route
            path="/support-statistic"
            element={<SupportStatistic />}
          ></Route>
        </Routes>
      </div>
    </div>
  );
}
