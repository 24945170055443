import { useParams } from "react-router-dom";
import MinerChart from "./miner";
import RPSChart from "./rps";
import Revenue from "./revenue";
import UserOnlineChart from "./online";
import LoginChart from "./login";
import UserRegisterChart from "./register";
import ItemChart from "./item";
import LockItemChart from "./lock";
import SessionChart from "./session";
import AdaptationChart from "./adaptation-chart";
import MarketPlaceChart from "./market-place-chart";
import NetworkChart from "./network-chart";
import FameChart from "./fame-chart";
import ArpuChart from "./arpu-chart";
export default function Chart() {
  const { type } = useParams();
  switch (type) {
    case "rps":
      return <RPSChart />;
    case "revenue":
      return <Revenue />;
    case "miner":
      return <MinerChart />;
    case "useronline":
      return <UserOnlineChart />;
    case "login":
      return <LoginChart />;
    case "register":
      return <UserRegisterChart />;
    case "item-mongen":
      return <ItemChart value={{ type: "mongen" }} />;
    case "item-plot":
      return <ItemChart value={{ type: "plot" }} />;
    case "item-land":
      return <ItemChart value={{ type: "land" }} />;
    case "lock":
      return <LockItemChart />;
    case "session":
      return <SessionChart />;
    case "adaptation":
      return <AdaptationChart />;
    case "marketplace":
      return <MarketPlaceChart />;
    case "network":
      return <NetworkChart />;
    case "userfame":
      return <FameChart />;
    case "arpu":
      return <ArpuChart />;
    default:
      return <p>Chart {type}</p>;
  }
}
