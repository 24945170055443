import { RewardEntity, RewardType } from "interfaces";
import { Button, Input } from "semantic-ui-react";
import Entity from "./entity";
import Enum from "./enum";

export default function RewardEditor({
  value,
  onChange,
  viewOnly,
}: {
  viewOnly?: boolean;
  value: RewardEntity[];
  onChange: Function;
}) {
  function addReward() {
    let tmp: RewardEntity[] = [...value];
    tmp.push({
      reward_type: null,
      reward_quantity: null,
      plot_type: null,
      race: null,
      rarity: null,
    });
    onChange(tmp);
  }
  function removeReward(index: number) {
    let tmp: RewardEntity[] = [...value];
    tmp.splice(index, 1);
    onChange(tmp);
  }
  function handleChange(index: number, name: string, val: any) {
    let tmp: RewardEntity[] = [...value];
    //@ts-ignore
    tmp[index][name] = val;
    onChange(tmp);
  }
  return (
    <div>
      {value.map((i: RewardEntity, index: number) => (
        <div
          key={index}
          className="flex gap-2 justify-between border hover:border-gray-200 border-white p-1"
        >
          <div className="w-full flex gap-2 items-center">
            <span className="font-semibold mr-2 w-6">{index + 1}.</span>
            <div className="w-44">
              <Enum
                placeholder="Reward"
                enumName="reward-type"
                value={i.reward_type}
                onChange={(val) => {
                  handleChange(index, "reward_type", Number(val));
                }}
              />
            </div>
            <div className="w-32">
              <Input
                className="w-32"
                value={i.reward_quantity || ""}
                placeholder="Quantity"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(
                    index,
                    "reward_quantity",
                    Number(evt.target.value)
                  );
                }}
              />
            </div>
            <div className="w-32">
              <Input
                className="w-32"
                value={i.rate || ""}
                placeholder="Open rate"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(index, "rate", Number(evt.target.value));
                }}
              />
            </div>

            {i.reward_type === RewardType.PLOT && (
              <div className="w-44">
                <Enum
                  placeholder="Plot type"
                  enumName="plot-type"
                  value={i.plot_type}
                  onChange={(val) => {
                    handleChange(index, "plot_type", Number(val));
                  }}
                />
              </div>
            )}
            {(i.reward_type === RewardType.PLOT ||
              i.reward_type === RewardType.SOULCORE) && (
              <div className="w-44">
                <Enum
                  placeholder="Race"
                  enumName="mongen-race"
                  value={i.race}
                  onChange={(val) => {
                    handleChange(index, "race", Number(val));
                  }}
                />
              </div>
            )}
            {(i.reward_type === RewardType.PLOT ||
              i.reward_type === RewardType.TICKET ||
              i.reward_type === RewardType.SOULCORE) && (
              <div className="w-44">
                <Enum
                  placeholder="Rarity"
                  enumName="rarity"
                  value={i.rarity}
                  onChange={(val) => {
                    handleChange(index, "rarity", Number(val));
                  }}
                />
              </div>
            )}
            {i.reward_type === RewardType.BOX && (
              <div className="w-44">
                <Entity
                  displayField={"name"}
                  value={i.reward_pool_id}
                  gridName={"reward-pool"}
                  multiple={false}
                  onChange={(val: any) => {
                    handleChange(index, "reward_pool_id", val);
                  }}
                />
              </div>
            )}
          </div>
          {!viewOnly && (
            <Button
              icon="times"
              color="red"
              onClick={() => {
                removeReward(index);
              }}
            />
          )}
        </div>
      ))}
      <div className="mt-2">
        {!viewOnly && (
          <Button
            icon="plus"
            content="Add"
            labelPosition="left"
            onClick={addReward}
          />
        )}
      </div>
    </div>
  );
}
