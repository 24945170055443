import { useEffect, useMemo, useState } from "react";
const RACES = ["Beast", "Tectos", "Mythic", "Celest", "Chaos"];
const TIERS = ["D", "C", "B", "A", "S"];
const BodyPart = {
  Form: 0,
  Head: 1,
  Eyes: 2,
  Horns: 3,
  Tail: 4,
  Back: 5,
  FrontLeg: 6,
  BackLeg: 7,
  Mouth: 8,
  Aura: 9,
};
const data = [];
const BackMap = { 0: 3, 2: 2, 1: 1 };
function getId() {
  if (!window.animationMonsterraId) {
    window.animationMonsterraId = 1;
  }
  return window.animationMonsterraId++;
}
let globalPlayer = null;
let isInit = false;
function initPlayer() {
  if (isInit) {
    return;
  }
  isInit = true;
  let playerId = "global-spine-player-hehehe";
  let container = document.createElement("div");
  container.id = playerId;
  container.style =
    "width:378px;height:378px;position: fixed; z-index: -9999999; opacity: 0";
  document.getElementById("root").appendChild(container);
  let instance = new window.spine.SpinePlayer(container, {
    premultipliedAlpha: true,
    // alpha: true,
    alpha: true,
    showControls: false,
    backgroundColor: "#00000000",
    jsonUrl: "/mongen/Pets.json",
    atlasUrl: "/mongen/Pets.atlas.txt",
    success: () => {
      globalPlayer = instance;
      console.log("globalplayer", globalPlayer);
    },
    error: () => {},
  });
}
function getSkinList(dna) {
  function gen(bodypart, name) {
    return `${RACES[dna[bodypart][0]]}/${TIERS[dna[bodypart][1]]}/${String(
      dna[bodypart][2]
    ).padStart(2, "0")}/${name}`;
  }
  function genBack(bodypart, name) {
    return `${RACES[dna[bodypart][0]]}/${TIERS[dna[bodypart][1]]}/${String(
      dna[bodypart][2]
    ).padStart(2, "0")}/${name}_${BackMap[dna[BodyPart.Form][0]]}`;
  }
  function genMouth() {
    return `Decor/Mouth/Mouth_${(dna[BodyPart.Mouth][2] + "").padStart(
      2,
      "0"
    )}`;
  }
  //form
  return [
    //mouth
    // gen(BodyPart.Mouth, "Mouth"),
    gen(BodyPart.Form, "Body"),
    genBack(BodyPart.Head, "Head"),
    genBack(BodyPart.Eyes, "Eyes"),
    gen(BodyPart.Horns, "Horns"),
    genBack(BodyPart.Tail, "Tail"),
    genBack(BodyPart.Back, "Back"),
    gen(BodyPart.FrontLeg, "FrontLegs"),
    gen(BodyPart.BackLeg, "BackLegs"),
    genMouth(),
  ];
}

function getAnimName(anim, dna) {
  let name = RACES[dna[BodyPart.Form][0]];
  return `${name}/${name}_${anim}`;
}

function applyDNA(dna) {
  try {
    globalPlayer.skeleton.skin.clear();
    let skins = getSkinList(dna);
    let mainSkin = new window.spine.Skin(skins[0]);
    for (var i = 0; i < skins.length; i++) {
      mainSkin.addSkin(globalPlayer.skeleton.data.findSkin(skins[i]));
    }
    globalPlayer.skeleton.setSkin(mainSkin);
    globalPlayer.skeleton.setSlotsToSetupPose();
    globalPlayer.skeleton.updateCache();
    globalPlayer.config.animation = getAnimName("Idle", dna);
    globalPlayer.playTime = 0;
    globalPlayer.setAnimation(getAnimName("Idle", dna), true);
  } catch (err) {
    console.log({ err });
  }
}
async function screenshot() {
  for (; true; ) {
    if (globalPlayer) {
      while (data.length > 0) {
        let info = data.pop();
        if (!info) {
          return;
        }
        applyDNA(info.dna);
        globalPlayer.drawFrame(false);
        if (info.callback) {
          info.callback(globalPlayer.canvas.toDataURL());
        }
      }
    } else {
      // console.log("player is not init");
    }

    await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }
}
function queryImage(dna, callback) {
  data.push({ dna, callback });
}
screenshot();
const mongenPlayer = { initPlayer, queryImage };
export { initPlayer, queryImage };
export default mongenPlayer;
