import { useCallback, useEffect, useState } from "react";
import { Button, Select } from "semantic-ui-react";
import StaticAnim from "./staticanim";
const RACES = ["Beast", "Tectos", "Mythic", "Celest", "Chaos"];
const TIERS = ["D", "C", "B", "A", "S"];
const BodyPart = [
    "Form",
    "Head",
    "Eyes",
    "Horns",
    "Tail",
    "Back",
    "FrontLeg",
    "BackLeg",
    "Mouth",
    "Aura",
];
// [statsList [race [rarity]]]
// race: beast - tectos - mystic - celest - chaos
// rarity: uncommon - common - rare - epic
const MaxBodyPart = [
    // statsList: Form - Head - Eyes - Horns - Tails - Back - Frontleg - Backleg - Mouth
    [
        [2, 1, 1, 1],
        [2, 1, 1, 1],
        [2, 1, 1, 1],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
    ],
    [
        [2, 1, 1, 1],
        [2, 1, 1, 1],
        [2, 1, 1, 1],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
    ],
    [
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
    ],
    [
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
    ],
    [
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
    ],
    [
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
    ],
    [
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
    ],
    [
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [3, 2, 2, 2],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
    ],
    [
        [24, 24, 24, 24],
        [24, 24, 24, 24],
        [24, 24, 24, 24],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
    ],
];
function Part({ idx, onChange, value, dna }: any) {
    return (
        <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
                <p style={{ margin: 0 }}>Race</p>
                <Select
                    value={value[0]}
                    options={
                        RACES.filter((_, index) => {
                            if (idx === 6 || idx === 7) {
                                return dna[0][0] === index
                            }
                            return (
                                index < 3
                            )
                        }).map((name, index) => {
                            return {
                                value: index,
                                text: name
                            }
                        })
                    }
                    disabled={idx === 6 || idx === 7}
                    style={{ width: 100 }}
                    onChange={(evt: any, val: any) => {
                        let tmp = [...value];
                        tmp[0] = Number(val.value);
                        onChange(tmp);
                    }}
                />
            </div>
            <div style={{ marginRight: 10 }}>
                <p style={{ margin: 0 }}>Tier</p>
                <Select
                    value={value[1]}
                    style={{ width: 100 }}
                    onChange={(evt: any, val: any) => {
                        let tmp = [...value];
                        tmp[1] = Number(val.value);
                        onChange(tmp);
                    }}
                    options={
                        TIERS.map((name, index) => {
                            return {
                                value: index,
                                text: name
                            }
                        })
                    }
                />
            </div >
            <div>
                <p style={{ margin: 0 }}>ID</p>
                <Select
                    value={value[2]}
                    style={{ width: 100 }}
                    onChange={(evt: any, val: any) => {
                        let tmp = [...value];
                        tmp[2] = Number(val.value);
                        onChange(tmp);
                    }}
                    options={[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                        20, 21, 22, 23, 24,
                    ].map((name) => {
                        return {
                            value: name,
                            text: name
                        }
                    })}
                />
            </div>
        </div >
    );
}
function DNAMaker() {
    const [anim, setAnim] = useState("Idle");
    const [dnaText, setDNAText] = useState("");
    const [dna, setDna] = useState([
        [0, 0, 1],
        [1, 0, 1],
        [0, 0, 1],
        [0, 1, 1],
        [2, 0, 2],
        [1, 0, 1],
        [0, 0, 2],
        [0, 0, 2],
        [0, 0, 1],
        [0],
    ]);
    function onKeyDown(evt: any) {
        switch (evt.keyCode) {
            case 37:
                onPrev();
                break;
            case 39:
                onNext();
                break;
        }
    }
    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [dna]);
    useEffect(() => {
        setDNAText(JSON.stringify(dna));
    }, [dna]);
    let onNext = useCallback(() => {
        let tmp = JSON.parse(JSON.stringify(dna));
        for (var i = tmp.length - 2; i >= 0; i--) {
            if (i === 8) {
                if (tmp[i][2] < MaxBodyPart[i][tmp[i][0]][tmp[i][1]]) {
                    tmp[i][2]++;
                    break;
                } else {
                    tmp[i][2] = 1;
                    continue;
                }
            }
            if (tmp[i][2] < MaxBodyPart[i][tmp[i][0]][tmp[i][1]]) {
                tmp[i][2]++;
                break;
            }
            tmp[i][2] = 1;
            if (tmp[i][1] < 3) {
                tmp[i][1]++;
                break;
            }
            tmp[i][1] = 0;
            if ([6, 7].includes(i)) {
                //legs
                if (tmp[i][0] < 2) {
                    tmp[i][0]++;
                }
                tmp[i][0] = 0;
            } else if (i === 0) {
                if (tmp[i][0] < 2) {
                    tmp[i][0]++;
                    tmp[6][0] = tmp[i][0];
                    tmp[7][0] = tmp[i][0];
                    break;
                }
            } else {
                if (tmp[i][0] < 2) {
                    tmp[i][0]++;
                    break;
                }
                tmp[i][0] = 0;
            }
        }
        setDna(tmp);
    }, [dna]);
    function onPrev() {
        let tmp = JSON.parse(JSON.stringify(dna));
        for (var i = tmp.length - 2; i >= 0; i--) {
            if (i === 8) {
                if (tmp[i][2] > 1) {
                    tmp[i][2]--;
                    break;
                } else {
                    tmp[i][2] = MaxBodyPart[i][tmp[i][0]][tmp[i][1]];
                    continue;
                }
            }
            if (tmp[i][2] > 1) {
                tmp[i][2]--;
                break;
            }

            if (tmp[i][1] > 0) {
                tmp[i][1]--;
                tmp[i][2] = MaxBodyPart[i][tmp[i][0]][tmp[i][1]];
                break;
            }
            tmp[i][1] = 3;
            if ([6, 7].includes(i)) {
                //legs
                if (tmp[i][0] > 0) {
                    tmp[i][0]--;
                }
                tmp[i][0] = tmp[0][0];
            } else if (i === 0) {
                if (tmp[i][0] > 0) {
                    tmp[i][0]--;
                    tmp[6][0] = tmp[i][0];
                    tmp[7][0] = tmp[i][0];
                    tmp[i][2] = MaxBodyPart[i][tmp[i][0]][tmp[i][1]];
                    break;
                }
            } else {
                if (tmp[i][0] > 0) {
                    tmp[i][0]--;
                    tmp[i][2] = MaxBodyPart[i][tmp[i][0]][tmp[i][1]];
                    break;
                }
                tmp[i][0] = 2;
            }
            tmp[i][2] = MaxBodyPart[i][tmp[i][0]][tmp[i][1]];
        }
        setDna(tmp);
    }
    return (
        <div tabIndex={0} style={{ display: "flex" }}>
            <div style={{ flexGrow: 0 }}>
                {BodyPart.map((name, index) => (
                    <div
                        style={{
                            width: "100%",
                            padding: 5,
                            display: "flex",
                            border: "1px solid #ddd",
                            marginTop: 10,
                        }}
                    >
                        <label style={{ marginRight: 15, width: 100 }}>
                            <b>{name}</b>
                        </label>
                        <Part
                            idx={index}
                            value={dna[index]}
                            onChange={(val: any) => {
                                let tmp = [...dna];
                                tmp[index] = val;
                                if (index === 0) {
                                    let raceForm = tmp[index][0];
                                    tmp[6][0] = raceForm;
                                    tmp[7][0] = raceForm;
                                }
                                setDna(tmp);
                            }}
                            dna={dna}
                        />
                    </div>
                ))}
            </div>
            <div style={{ width: "100%" }} className="ml-4 mt-3">
                <div style={{ border: "1px solid #cecece", padding: 5 }}>
                    <div>
                        <Button
                            color="blue"
                            style={{ marginRight: 4 }} onClick={onPrev}>
                            Prev
                        </Button>
                        <Button
                            color="blue"
                            onClick={onNext}>Next
                        </Button>
                        <Button
                            color="green"
                            onClick={
                                () => {
                                    navigator.clipboard.writeText(JSON.stringify(dna));
                                }
                            }>Copy
                        </Button>
                    </div>
                    <div className="flex mt-4">
                        <span className="mr-2">
                            DNA:
                        </span>
                        <input
                            type="textarea"
                            value={dnaText}
                            style={{ width: "100%" }}
                            onChange={(evt) => {
                                try {
                                    let value = evt.target.value;
                                    let d = JSON.parse(value);
                                    setDna(d);
                                } catch (error) {
                                    console.log("invalid dna");
                                }
                            }}
                        />
                    </div>

                </div>
                <StaticAnim
                    dna={dna}
                />
            </div>
        </div>
    );
}

export default DNAMaker;
