import dayjs from "dayjs";
import BasicChart from "./basic-chart";

export default function UserOnlineChart() {
  return (
    <BasicChart
      url="/operation/get-user-online-report"
      chartType="line"
      value={{
        group: "hour",
        dateFrom: dayjs().add(-10, "hour").startOf("hour").toDate(),
        dateTo: dayjs().endOf("hour").toDate(),
      }}
    />
  );
}
