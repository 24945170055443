import BasicChart from "./basic-chart";

export default function NetworkChart(props: any) {
  return (
    <BasicChart
      {...props}
      url="/operation/get-network-report"
      chartType="line"
    />
  );
}
