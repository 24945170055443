import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import { api } from "services";
export default function MiniChart({
  url,
  dateFrom,
  dateTo,
  input,
}: {
  input?: any;
  url: string;
  dateFrom: Date;
  dateTo: Date;
}) {
  const [data, setData] = useState(null);
  useEffect(() => {
    async function loadData() {
      let rs = await api.post(url, {
        dateFrom,
        dateTo,
        groupBy: "date",
        ...input,
      });
      setData(rs);
    }
    loadData();
  }, [url]);
  return data === null ? (
    <p>Loading...</p>
  ) : (
    <Line
      data={data.data}
      options={{
        scales: {
          y: {
            display: true,
            ticks: {
              display: false,
            },
          },
          x: {
            display: false,
          },
        },
      }}
    />
  );
}
