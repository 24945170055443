import { useEffect, useMemo } from "react";
import dataService from "../services/data";
import { EnumItem, EnumEntity } from "interfaces";
import { Select } from "semantic-ui-react";
interface EnumProps {
  value: string | number | string[] | number[];
  enumName: string;
  multiple?: boolean;
  dataType?: "string" | "number" | "boolean" | "array" | "object";
  onChange?: (val: string | number | string[] | number[]) => void;
  placeholder?: string;
  disabledValue?: any[];
}
function Enum({
  value,
  enumName,
  multiple,
  onChange,
  placeholder,
  disabledValue = [],
}: EnumProps): React.ReactElement {
  const enumInfo = useMemo((): EnumEntity => {
    let rs: EnumEntity = dataService.getEnum(enumName);
    return rs;
  }, [enumName]);
  if (!enumInfo) {
    return <p>Enum not found</p>;
  }

  return (
    <div>
      <Select
        placeholder={placeholder}
        fluid
        multiple={multiple}
        value={value === undefined ? (multiple ? [] : null) : value}
        options={enumInfo.items.filter((i) => !disabledValue.includes(i.value)).map((i) => {
          return { text: i.label, value: i.value };
        })}
        onChange={(evt: any, val: any) => {
          onChange(val.value);
        }}
      />
    </div>
  );
}
export default Enum;
