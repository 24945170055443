import { DatePicker } from "components";
import _ from "lodash";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useLocation, useParams } from "react-router-dom";
import { Button, Card, Form, Input } from "semantic-ui-react";
import { api, ui } from "services";

export default function CustomerInfo() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const [banReason, setBanReason] = useState<string>("");
  const [customerInfo, setCustomerInfo] = useState(null);
  const [blockUntil, setBlockUntil] = useState<Date>(null);
  useEffect(() => {
    async function loadCustomerInfo() {
      let rs = await api.post("/operation/get-customer-info", { id });
      setCustomerInfo(rs);
    }
    loadCustomerInfo();
  }, [id]);
  function handleChange(name: string, value: any) {
    let tmp = _.cloneDeep(customerInfo);
    tmp[name] = value;
    setCustomerInfo(tmp);
  }
  async function unban() {
    await ui.confirm("Are you sure want to unban this user?");
    let rs = await api.post("/operation/unblock-customer", {
      id,
    });
    handleChange("block_until", null);
    ui.alert("Unban success");
  }
  async function ban() {
    if (!(banReason && blockUntil)) {
      return ui.alert("Please enter reason");
    }
    await ui.confirm("Are you sure want to ban this user?");
    let rs = await api.post("/operation/block-customer", {
      customer_id: id,
      block_until: blockUntil,
      reason: banReason,
    });
    handleChange("block_reason", banReason);
    handleChange("block_until", blockUntil);
    ui.alert("Ban success");
  }
  return (
    <div>
      <Card fluid>
        <Card.Content>
          <div className="flex justify-between">
            <p>
              Customer Info <b>#{id}</b>
            </p>
          </div>
        </Card.Content>
        <Card.Content>
          <Form>
            <div className="flex w-full gap-2">
              <Form.Field className="w-full">
                <label>Ban reason</label>
                <Input
                  value={banReason}
                  type="text"
                  fluid
                  onChange={(evt: any) => {
                    setBanReason(evt.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field className="w-full">
                <label>Block Until</label>
                <DatePicker
                  selected={blockUntil || new Date()}
                  onChange={(val: Date) => {
                    setBlockUntil(val);
                  }}
                />
              </Form.Field>
              {customerInfo?.block_until < new Date() ? (
                <Button
                  onClick={() => {
                    ban();
                  }}
                  icon="ban"
                  content="Ban"
                  labelPosition="left"
                  color="red"
                />
              ) : (
                <Button
                  onClick={() => {
                    unban();
                  }}
                  icon="check"
                  content="Unban"
                  labelPosition="left"
                  color="green"
                />
              )}
            </div>
          </Form>
        </Card.Content>
        <Card.Content>
          {customerInfo !== null && (
            <ReactJson
              src={customerInfo}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              name="input"
            />
          )}
        </Card.Content>
      </Card>
    </div>
  );
}
