const Rarity = { Common: 0, Uncommon: 1, Rare: 2, Epic: 3, Legend: 4 };
const Config = {
  PlotTypes: [
    {
      name: "Landcore",
      rarity: Rarity.Common,
      img: "Landcore/Core_1.png",
      size: [2, 2],
      type: 0,
      race: 5,
    },
    {
      name: "Landcore",
      rarity: Rarity.Uncommon,
      img: "Landcore/Core_2.png",
      size: [2, 2],
      type: 0,
      race: 5,
    },
    {
      name: "Landcore",
      rarity: Rarity.Rare,
      img: "Landcore/Core_3.png",
      size: [2, 2],
      type: 0,
      race: 5,
    },
    {
      name: "Hatching",
      rarity: Rarity.Common,
      img: "Hatching/Hatch_1.png",
      size: [2, 2],
      type: 3,
      race: 5,
    },
    {
      name: "Hatching",
      rarity: Rarity.Uncommon,
      img: "Hatching/Hatch_2.png",
      size: [2, 2],
      type: 3,
      race: 5,
    },
    {
      name: "Hatching",
      rarity: Rarity.Rare,
      img: "Hatching/Hatch_3.png",
      size: [2, 2],
      type: 3,
      race: 5,
    },
    {
      name: "Breeding",
      rarity: Rarity.Common,
      img: "Breed/Breed_1.png",
      size: [2, 2],
      type: 2,
      race: 5,
    },
    {
      name: "Breeding",
      rarity: Rarity.Uncommon,
      img: "Breed/Breed_2.png",
      size: [2, 2],
      type: 2,
      race: 5,
    },
    {
      name: "Breeding",
      rarity: Rarity.Rare,
      img: "Breed/Breed_3.png",
      size: [2, 2],
      type: 2,
      race: 5,
    },
    {
      name: "Production",
      rarity: Rarity.Common,
      img: "Production/Prodcution_1.png",
      size: [1, 1],
      type: 4,
      race: 5,
    },
    {
      name: "Production",
      rarity: Rarity.Uncommon,
      img: "Production/Production_2.png",
      size: [2, 1],
      type: 4,
      race: 5,
    },
    {
      name: "Production",
      rarity: Rarity.Rare,
      img: "Production/Production_3.png",
      size: [2, 2],
      type: 4,
      race: 5,
    },
    {
      name: "Storage",
      rarity: Rarity.Common,
      img: "Storage/Storage_1.png",
      size: [2, 2],
      type: 5,
      race: 5,
    },
    {
      name: "Storage",
      rarity: Rarity.Uncommon,
      img: "Storage/Storage_2.png",
      size: [2, 2],
      type: 5,
      race: 5,
    },
    {
      name: "Storage",
      rarity: Rarity.Rare,
      img: "Storage/Storage_3.png",
      size: [2, 2],
      type: 5,
      race: 5,
    },
    {
      name: "Pasture Beast",
      rarity: Rarity.Common,
      img: "Beast/Beast_1.png",
      size: [1, 1],
      type: 1,
      race: 0,
    },
    {
      name: "Pasture Beast",
      rarity: Rarity.Uncommon,
      img: "Beast/Beast_2.png",
      size: [2, 1],
      type: 1,
      race: 0,
    },
    {
      name: "Pasture Beast",
      rarity: Rarity.Rare,
      img: "Beast/Beast_3.png",
      size: [2, 2],
      type: 1,
      race: 0,
    },
    {
      name: "Pasture Mystic",
      rarity: Rarity.Common,
      img: "Mystic/Mystic_1.png",
      size: [1, 1],
      type: 1,
      race: 2,
    },
    {
      name: "Pasture Mystic",
      rarity: Rarity.Uncommon,
      img: "Mystic/Mystic_2.png",
      size: [2, 1],
      type: 1,
      race: 2,
    },
    {
      name: "Pasture Mystic",
      rarity: Rarity.Rare,
      img: "Mystic/Mystic_3.png",
      size: [2, 2],
      type: 1,
      race: 2,
    },
    {
      name: "Pasture Tectos",
      rarity: Rarity.Common,
      img: "Tectos/Tectos_1.png",
      size: [1, 1],
      type: 1,
      race: 1,
    },
    {
      name: "Pasture Tectos",
      rarity: Rarity.Uncommon,
      img: "Tectos/Tectos_2.png",
      size: [2, 1],
      type: 1,
      race: 1,
    },
    {
      name: "Pasture Tectos",
      rarity: Rarity.Rare,
      img: "Tectos/Tectos_3.png",
      size: [2, 2],
      type: 1,
      race: 1,
    },
    {
      name: "Camp",
      rarity: Rarity.Common,
      img: "Camp/0.png",
      size: [2, 2],
      type: 6,
      race: 1,
    },
    {
      name: "Camp",
      rarity: Rarity.Uncommon,
      img: "Camp/1.png",
      size: [2, 2],
      type: 6,
      race: 1,
    },
    {
      name: "Camp",
      rarity: Rarity.Rare,
      img: "Camp/2.png",
      size: [2, 2],
      type: 6,
      race: 1,
    },
    {
      name: "Camp",
      rarity: Rarity.Epic,
      img: "Camp/3.png",
      size: [2, 2],
      type: 6,
      race: 1,
    },
    {
      name: "Camp",
      rarity: Rarity.Legend,
      img: "Camp/4.png",
      size: [2, 2],
      type: 6,
      race: 1,
    },
    {
      name: "Tower",
      rarity: Rarity.Common,
      img: "Tower/0.png",
      size: [2, 2],
      type: 7,
      race: 1,
    },
    {
      name: "Tower",
      rarity: Rarity.Uncommon,
      img: "Tower/1.png",
      size: [2, 2],
      type: 7,
      race: 1,
    },
    {
      name: "Tower",
      rarity: Rarity.Rare,
      img: "Tower/2.png",
      size: [2, 2],
      type: 7,
      race: 1,
    },
    {
      name: "Tower",
      rarity: Rarity.Epic,
      img: "Tower/3.png",
      size: [2, 2],
      type: 7,
      race: 1,
    },
    {
      name: "Tower",
      rarity: Rarity.Legend,
      img: "Tower/4.png",
      size: [2, 2],
      type: 7,
      race: 1,
    },
    {
      name: "Bombard",
      rarity: Rarity.Common,
      img: "Bombard/0.png",
      size: [2, 2],
      type: 8,
      race: 1,
    },
    {
      name: "Bombard",
      rarity: Rarity.Uncommon,
      img: "Bombard/1.png",
      size: [2, 2],
      type: 8,
      race: 1,
    },
    {
      name: "Bombard",
      rarity: Rarity.Rare,
      img: "Bombard/2.png",
      size: [2, 2],
      type: 8,
      race: 1,
    },
    {
      name: "Bombard",
      rarity: Rarity.Epic,
      img: "Bombard/3.png",
      size: [2, 2],
      type: 8,
      race: 1,
    },
    {
      name: "Bombard",
      rarity: Rarity.Legend,
      img: "Bombard/4.png",
      size: [2, 2],
      type: 8,
      race: 1,
    },
    {
      name: "Trap",
      rarity: Rarity.Common,
      img: "Trap/0.png",
      size: [1, 1],
      type: 9,
      race: 1,
    },
    {
      name: "Trap",
      rarity: Rarity.Uncommon,
      img: "Trap/1.png",
      size: [1, 1],
      type: 9,
      race: 1,
    },
    {
      name: "Trap",
      rarity: Rarity.Rare,
      img: "Trap/2.png",
      size: [1, 1],
      type: 9,
      race: 1,
    },
    {
      name: "Trap",
      rarity: Rarity.Epic,
      img: "Trap/3.png",
      size: [1, 1],
      type: 9,
      race: 1,
    },
    {
      name: "Trap",
      rarity: Rarity.Legend,
      img: "Trap/4.png",
      size: [1, 1],
      type: 9,
      race: 1,
    },
    {
      name: "Barrack",
      rarity: Rarity.Common,
      img: "Barrack/0.png",
      size: [1, 1],
      type: 10,
      race: 1,
    },
    {
      name: "Barrack",
      rarity: Rarity.Uncommon,
      img: "Barrack/1.png",
      size: [2, 1],
      type: 10,
      race: 1,
    },
    {
      name: "Barrack",
      rarity: Rarity.Rare,
      img: "Barrack/2.png",
      size: [2, 2],
      type: 10,
      race: 1,
    },
    {
      name: "Barrack",
      rarity: Rarity.Epic,
      img: "Barrack/3.png",
      size: [3, 3],
      type: 10,
      race: 1,
    },
    {
      name: "Barrack",
      rarity: Rarity.Legend,
      img: "Barrack/4.png",
      size: [3, 3],
      type: 10,
      race: 1,
    },
  ],
  MongenState: ["Egg", "Idle", "Breeding", "Working"],
  PlotType: [
    "LandCore",
    "Pasture",
    "Breeding",
    "Hatching",
    "Production",
    "Storage",
    "Camp",
    "Tower",
    "Bombard",
    "Trap",
    "Barracks",
    "Decor",
    "All",
  ],
  NFTType: ["MONGEN", "SOUL_CORE", "LAND", "PLOT", "EVOL_ITEM", "SKILL_STONE"],
  Rarity: ["Common", "Uncommon", "Rare", "Epic", "Legendary"],
  Race: ["Beast", "Tectos", "Mystic", "Celest", "Chaos", "All"],
  CustomerResources: [
    "Food",
    "MAG",
    "MSTR",
    "EvolutionItem",
    "NormalStone",
    "RerollStone",
    "EliteStone",
    "NormalRuneStone",
    "RerollRuneStone",
    "EliteRuneStone",
    "Experience",
    "TicketCO",
    "TicketUC",
    "TicketRA",
    "TicketEP",
    "TicketLE",
  ],
  RewardType: [
    "FOOD",
    "EVOLUTION_ITEM",
    "MAG",
    "EXP",
    "SOULCORE",
    "MSTR",
    "PLOT",
    "BOX",
    "TICKET",
    "NormalStone",
    "RerollStone",
    "EliteStone",
    "NormalRuneStone",
    "RerollRuneStone",
    "EliteRuneStone",
  ],
  PlotEnumType: {
    LandCore: 0,
    Pasture: 1,
    Breeding: 2,
    Hatching: 3,
    Production: 4,
    Storage: 5,
    Camp: 6,
    Tower: 7,
    Bombard: 8,
    Trap: 9,
    Barracks: 10,
    Decor: 11,
    All: 12,
  },
  LandcoreStats: [
    // statsList: HP - Limit - Upgrade cost - Upgrade time
    [
      [1250, 1400, 1550, 1750, 2000, 2250, 2500, 2850, 3200, 3600],
      [22, 26, 32, 36, 42, 46, 52, 58, 62, 68],
      [0, 10, 55, 85, 345, 690, 860, 1725, 2760, 3455],
      [0, 1440, 5760, 8640, 34560, 69120, 86400, 172800, 276480, 345600],
    ],
    [
      [1600, 1800, 2000, 2250, 2550, 2850, 3200, 3600, 4100, 4600],
      [30, 36, 44, 50, 58, 64, 72, 78, 86, 92],
      [0, 25, 115, 170, 690, 1380, 1725, 3455, 5525, 6910],
      [0, 2880, 11520, 17280, 69120, 138240, 172800, 345600, 552960, 691200],
    ],
    [
      [2000, 2250, 2500, 2800, 3200, 3600, 4050, 4550, 5100, 5750],
      [40, 48, 58, 68, 76, 86, 96, 104, 114, 124],
      [0, 40, 170, 255, 1035, 2070, 96, 5180, 8290, 10365],
      [0, 4320, 17280, 25920, 103680, 207360, 259200, 518400, 829440, 1036800],
    ],
    [
      [2500, 2800, 3150, 3550, 4000, 4500, 5050, 5700, 6400, 7200],
      [52, 64, 76, 88, 100, 112, 124, 136, 148, 160],
      [0, 55, 230, 345, 1380, 2760, 3455, 6910, 11055, 13820],
      [0, 5760, 23040, 34560, 138240, 276480, 345600, 691200, 1105920, 1382400],
    ],
    [
      [3150, 3500, 3950, 4450, 5000, 5650, 6350, 7150, 8050, 9050],
      [68, 82, 98, 114, 130, 146, 162, 178, 194, 210],
      [0, 70, 285, 430, 1725, 3455, 4320, 8640, 13820, 17280],
      [0, 7200, 28800, 43200, 172800, 345600, 432000, 864000, 1382400, 1728000],
    ],
  ],
  PastureStats: [
    // statsList: HP - Slot - Feed bonus - Main Race - Sub Race - Upgrade cost - Upgrade time - Landcore level
    [
      [125, 140, 155, 175, 195, 220, 245, 275, 305, 345],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 10, 35, 55, 85, 345, 600, 1555, 2415, 2760],
      [0, 1440, 3600, 5760, 8640, 34560, 60480, 155520, 241920, 276480],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [250, 280, 310, 350, 390, 440, 490, 550, 615, 690],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 3],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 25, 70, 115, 170, 690, 1205, 3110, 4835, 5525],
      [0, 2880, 7200, 11520, 17280, 69120, 120960, 311040, 483840, 552960],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [300, 335, 375, 420, 470, 525, 590, 660, 740, 830],
      [4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [1.5, 1.5, 1.5, 1.5, 1.5, 2.5, 2.5, 2.5, 2.5, 5],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 40, 105, 170, 255, 1035, 1810, 4665, 7255, 8290],
      [0, 4320, 10800, 17280, 25920, 103680, 181440, 466560, 725760, 829440],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [350, 390, 435, 490, 550, 615, 690, 770, 865, 970],
      [4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [2, 2, 2, 2, 2, 4, 4, 4, 4, 8],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 55, 140, 230, 345, 1380, 2415, 6220, 9675, 11055],
      [0, 5760, 14400, 23040, 34560, 138240, 241920, 622080, 967680, 1105920],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
    [
      [400, 445, 500, 560, 625, 700, 785, 880, 990, 1105],
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [2.5, 2.5, 2.5, 2.5, 2.5, 5, 5, 5, 5, 10],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 70, 180, 285, 430, 1725, 3020, 7775, 12095, 13820],
      [0, 7200, 18000, 28800, 43200, 172800, 302400, 777600, 1209600, 1382400],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ],
  ],
  BreedingStats: [
    // statsList: HP - Duration - Upgrade cost - Upgrade time - Landcore level
    [
      [125, 140, 155, 175, 200],
      [0, 0, 0, 0, 0],
      [0, 85, 255, 860, 1725],
      [0, 8640, 25920, 86400, 172800],
      [1, 3, 5, 7, 10],
    ],
    [
      [200, 225, 250, 280, 320],
      [1, 2, 3, 4, 5],
      [0, 170, 515, 1725, 3455],
      [0, 17280, 51840, 172800, 345600],
      [1, 3, 5, 7, 10],
    ],
    [
      [275, 305, 345, 390, 440],
      [2, 4, 6, 8, 10],
      [0, 255, 775, 2590, 5180],
      [0, 25920, 77760, 259200, 518400],
      [1, 3, 5, 7, 10],
    ],
    [
      [350, 390, 440, 495, 560],
      [5, 10, 15, 20, 25],
      [0, 345, 1035, 3455, 6910],
      [0, 34560, 103680, 345600, 691200],
      [1, 3, 5, 7, 10],
    ],
    [
      [400, 450, 505, 565, 640],
      [10, 20, 30, 40, 50],
      [0, 430, 1295, 4320, 8640],
      [0, 43200, 129600, 432000, 864000],
      [1, 3, 5, 7, 10],
    ],
  ],
  HatchingStats: [
    // statsList: HP - Duration - Upgrade cost - Upgrade time - Landcore level
    [
      [125, 140, 155, 175, 200],
      [0, 0, 0, 0, 0],
      [0, 85, 255, 860, 1725],
      [0, 8640, 25920, 86400, 172800],
      [1, 3, 5, 7, 10],
    ],
    [
      [200, 225, 250, 280, 320],
      [1, 2, 3, 4, 5],
      [0, 170, 515, 1725, 3455],
      [0, 17280, 51840, 172800, 345600],
      [1, 3, 5, 7, 10],
    ],
    [
      [275, 305, 345, 390, 440],
      [2, 4, 6, 8, 10],
      [0, 255, 775, 2590, 5180],
      [0, 25920, 77760, 259200, 518400],
      [1, 3, 5, 7, 10],
    ],
    [
      [350, 390, 440, 495, 560],
      [5, 10, 15, 20, 25],
      [0, 345, 1035, 3455, 6910],
      [0, 34560, 103680, 345600, 691200],
      [1, 3, 5, 7, 10],
    ],
    [
      [400, 450, 505, 565, 640],
      [10, 20, 30, 40, 50],
      [0, 430, 1295, 4320, 8640],
      [0, 43200, 129600, 432000, 864000],
      [1, 3, 5, 7, 10],
    ],
  ],
  ProductionStats: [
    // statsList: HP - Unlock food - Upgrade cost - Upgrade time - Landcore level
    [
      [
        200, 215, 235, 255, 280, 305, 335, 365, 395, 430, 470, 515, 560, 610,
        665,
      ],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 5, 5, 20, 40, 55, 70, 85, 115, 140, 285, 575, 1035],
      [
        0, 12, 48, 120, 480, 2160, 4320, 5760, 7200, 8640, 11520, 14400, 28800,
        57600, 103680,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        250, 270, 295, 320, 350, 380, 415, 455, 495, 540, 590, 645, 700, 765,
        835,
      ],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 5, 5, 40, 85, 115, 140, 170, 230, 285, 575, 1150, 2070],
      [
        0, 24, 96, 240, 960, 4320, 8640, 11520, 14400, 17280, 23040, 28800,
        57600, 115200, 207360,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        300, 325, 355, 385, 420, 460, 500, 545, 595, 650, 710, 770, 840, 915,
        1000,
      ],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 5, 10, 60, 125, 170, 215, 255, 345, 430, 860, 1725, 3110],
      [
        0, 36, 144, 360, 1440, 6480, 12960, 17280, 21600, 25920, 34560, 43200,
        86400, 172800, 311040,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        350, 380, 415, 450, 490, 535, 585, 635, 695, 760, 825, 900, 980, 1070,
        1165,
      ],
      [1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 5, 15, 85, 170, 230, 285, 345, 460, 575, 1150, 2300, 4145],
      [
        0, 48, 192, 480, 1920, 8640, 17280, 23040, 28800, 34560, 46080, 57600,
        115200, 230400, 414720,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        400, 435, 475, 515, 560, 615, 670, 730, 795, 865, 945, 1030, 1125, 1225,
        1335,
      ],
      [0, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3],
      [0, 5, 5, 5, 20, 105, 215, 285, 360, 430, 575, 720, 1440, 2880, 5180],
      [
        1, 60, 240, 600, 2400, 10800, 21600, 28800, 36000, 43200, 57600, 72000,
        144000, 288000, 518400,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
  ],
  StorageStats: [
    // statsList: HP - Capacity - Upgrade cost - Upgrade time - Landcore level
    [
      [
        480, 500, 600, 650, 750, 850, 950, 1050, 1200, 1350, 1550, 1750, 1950,
        2200, 2450,
      ],
      [
        880, 1350, 2150, 3400, 5400, 8400, 13250, 21000, 32500, 52000, 81000,
        127500, 200000, 315000, 500000,
      ],
      [0, 5, 5, 5, 10, 20, 25, 35, 55, 85, 115, 515, 1035, 1555, 2070],
      [
        0, 60, 240, 720, 1440, 2160, 2880, 3600, 5760, 8640, 11520, 51840,
        103680, 155520, 207360,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        540, 600, 650, 750, 850, 950, 1050, 1200, 1350, 1550, 1750, 1950, 2200,
        2450, 2800,
      ],
      [
        1780, 2750, 4350, 6900, 10800, 17000, 26850, 42000, 66500, 105000,
        164000, 258000, 405000, 637000, 1000000,
      ],
      [0, 5, 5, 10, 25, 40, 55, 70, 115, 170, 230, 1035, 2070, 3110, 4145],
      [
        0, 120, 480, 1440, 2880, 4320, 5760, 7200, 11520, 17280, 23040, 103680,
        207360, 311040, 414720,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        600, 650, 750, 850, 950, 1050, 1200, 1350, 1500, 1700, 1900, 2150, 2450,
        2750, 3100,
      ],
      [
        3500, 5600, 8800, 14000, 21750, 34000, 54000, 85000, 133000, 209000,
        328500, 516500, 812000, 1276000, 2000000,
      ],
      [0, 5, 5, 20, 40, 60, 85, 105, 170, 255, 345, 1555, 3110, 4665, 6220],
      [
        0, 180, 720, 2160, 4320, 6480, 8640, 10800, 17280, 25920, 34560, 155520,
        311040, 466560, 622080,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        660, 700, 800, 900, 1050, 1150, 1300, 1500, 1650, 1900, 2100, 2400,
        2700, 3050, 3400,
      ],
      [
        5250, 8250, 12950, 20350, 32000, 50250, 79000, 124500, 196000, 308000,
        483800, 760000, 1196000, 1879500, 3000000,
      ],
      [0, 5, 5, 25, 55, 85, 115, 140, 230, 345, 460, 2070, 4145, 6220, 8290],
      [
        0, 240, 960, 2880, 5760, 8640, 11520, 14400, 23040, 34560, 46080,
        207360, 414720, 622080, 829440,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
    [
      [
        720, 800, 900, 1000, 1150, 1250, 1450, 1600, 1800, 2050, 2300, 2600,
        2950, 3300, 3700,
      ],
      [
        7250, 11350, 17900, 28000, 44250, 70000, 109400, 172000, 270000, 425000,
        668000, 1050000, 1650000, 2550000, 4000000,
      ],
      [0, 5, 10, 35, 70, 105, 140, 180, 285, 430, 575, 2590, 5180, 7775, 10365],
      [
        0, 300, 1200, 3600, 7200, 10800, 14400, 18000, 28800, 43200, 57600,
        259200, 518400, 777600, 1036800,
      ],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
    ],
  ],
  CampStats: [
    // statsList: HP - Regen - Radius - Slot - Upgrade cost - Upgrade time - Landcore level
    [
      [125, 140, 155, 175, 200, 225, 250, 285, 320, 360],
      [1, 1.08, 1.17, 1.26, 1.36, 1.47, 1.59, 1.71, 1.85, 2],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 10, 35, 55, 85, 345, 600, 1555, 2415, 2760],
      [0, 1440, 3600, 5760, 8640, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [125, 140, 155, 175, 200, 225, 250, 285, 320, 360],
      [1.5, 1.62, 1.75, 1.89, 44288, 2.2, 2.38, 2.57, 2.78, 3],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 25, 70, 115, 170, 690, 1205, 3110, 4835, 5525],
      [0, 2880, 7200, 11520, 17280, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [275, 305, 345, 390, 440, 495, 555, 625, 705, 790],
      [44317, 1.62, 1.75, 1.89, 44288, 2.2, 2.38, 2.57, 2.78, 3],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [0, 40, 105, 170, 255, 1035, 1810, 4665, 7255, 8290],
      [0, 4320, 10800, 17280, 25920, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [350, 390, 440, 495, 560, 630, 705, 795, 895, 1010],
      [44317, 1.62, 1.75, 1.89, 2.04, 2.2, 2.38, 2.57, 2.78, 3],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      [0, 55, 140, 230, 345, 1380, 2415, 6220, 9675, 11055],
      [0, 5760, 14400, 23040, 34560, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [400, 450, 505, 565, 640, 720, 810, 910, 1025, 1150],
      [44318, 2.7, 2.92, 3.15, 3.4, 3.67, 3.97, 4.28, 4.63, 5],
      [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      [4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      [0, 70, 180, 285, 430, 1725, 3020, 7775, 12095, 13820],
      [0, 7200, 18000, 28800, 43200, 172800, 302400, 777600, 1209600, 1382400],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],
  TowerStats: [
    // statsList: HP - Bonus DMG - Slot - Upgrade cost - Upgrade time - Landcore level
    [
      [350, 390, 440, 495, 560, 630, 705, 795, 895, 1010],
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 10, 35, 55, 85, 345, 600, 1555, 2415, 2760],
      [0, 1440, 3600, 5760, 8640, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [420, 470, 530, 595, 670, 755, 850, 955, 1075, 1210],
      [5, 5.4, 5.83, 6.3, 6.8, 7.35, 7.93, 8.57, 9.25, 10],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 25, 70, 115, 170, 690, 1205, 3110, 4835, 5525],
      [0, 2880, 7200, 11520, 17280, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [490, 550, 620, 695, 780, 880, 990, 1115, 1255, 1410],
      [5, 5.65, 6.38, 7.21, 8.15, 9.2, 10.4, 11.75, 13.27, 15],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 40, 105, 170, 255, 1035, 1810, 4665, 7255, 8290],
      [0, 4320, 10800, 17280, 25920, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [560, 630, 705, 795, 895, 1005, 1135, 1275, 1435, 1615],
      [5, 5.83, 6.8, 7.94, 9.26, 10.8, 12.6, 14.69, 17.14, 20],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 55, 140, 230, 345, 1380, 2415, 6220, 9675, 11055],
      [0, 5760, 14400, 23040, 34560, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [630, 705, 795, 895, 1005, 1135, 1275, 1435, 1615, 1815],
      [5, 5.98, 7.15, 8.55, 10.22, 12.23, 14.62, 17.48, 20.9, 25],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 70, 180, 285, 430, 1725, 3020, 7775, 12095, 13820],
      [0, 7200, 18000, 28800, 43200, 172800, 302400, 777600, 1209600, 1382400],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],
  BombardStats: [
    // statsList: HP - Bonus DMG - Slot - Upgrade cost - Upgrade time - Landcore level
    [
      [400, 450, 505, 565, 640, 720, 810, 910, 1025, 1150],
      [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 10, 35, 55, 85, 345, 600, 1555, 2415, 2760],
      [0, 1440, 3600, 5760, 8640, 34560, 60480, 155520, 241920, 276480],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [480, 540, 605, 680, 765, 860, 970, 1090, 1230, 1385],
      [5, 5.4, 5.83, 6.3, 6.8, 7.35, 7.93, 8.57, 9.25, 10],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 25, 70, 115, 170, 690, 1205, 3110, 4835, 5525],
      [0, 2880, 7200, 11520, 17280, 69120, 120960, 311040, 483840, 552960],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [560, 630, 705, 795, 895, 1005, 1135, 1275, 1435, 1615],
      [5, 5.65, 6.38, 7.21, 8.15, 9.2, 10.4, 11.75, 13.27, 15],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 40, 105, 170, 255, 1035, 1810, 4665, 7255, 8290],
      [0, 4320, 10800, 17280, 25920, 103680, 181440, 466560, 725760, 829440],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [640, 720, 810, 910, 1025, 1150, 1295, 1455, 1640, 1845],
      [5, 5.83, 6.8, 7.94, 9.26, 10.8, 12.6, 14.69, 17.14, 20],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 55, 140, 230, 345, 1380, 2415, 6220, 9675, 11055],
      [0, 5760, 14400, 23040, 34560, 138240, 241920, 622080, 967680, 1105920],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
    [
      [720, 810, 910, 1025, 1150, 1295, 1455, 1640, 1845, 2075],
      [5, 5.98, 7.15, 8.55, 10.22, 12.23, 14.62, 17.48, 20.9, 25],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 70, 180, 285, 430, 1725, 3020, 7775, 12095, 13820],
      [0, 7200, 18000, 28800, 43200, 172800, 302400, 777600, 1209600, 1382400],
      [1, 1, 2, 2, 3, 3, 4, 4, 5, 6],
    ],
  ],
  LandcoreStatsList: {
    HP: 0,
    Limit: 1,
    UpgradeCost: 2,
    UpgradeTime: 3,
  },
  PastureStatsList: {
    HP: 0,
    Slot: 1,
    FeedBonus: 2,
    MainRace: 3,
    SubRace: 4,
    UpgradeCost: 5,
    UpgradeTime: 6,
    LandcoreLevel: 7,
  },
  BreedingStatsList: {
    HP: 0,
    Duration: 1,
    UpgradeCost: 2,
    UpgradeTime: 3,
    LandcoreLevel: 4,
  },

  HatchingStatsList: {
    HP: 0,
    Duration: 1,
    UpgradeCost: 2,
    UpgradeTime: 3,
    LandcoreLevel: 4,
  },

  ProductionStatsList: {
    HP: 0,
    UnlockFood: 1,
    UpgradeCost: 2,
    UpgradeTime: 3,
    LandcoreLevel: 4,
  },

  StorageStatsList: {
    HP: 0,
    Capacity: 1,
    UpgradeCost: 2,
    UpgradeTime: 3,
    LandcoreLevel: 4,
  },

  CampStatsList: {
    HP: 0,
    Regen: 1,
    Radius: 2,
    Slot: 3,
    UpgradeCost: 4,
    UpgradeTime: 5,
    LandcoreLevel: 6,
  },

  TowerStatsList: {
    HP: 0,
    BonusDMG: 1,
    Slot: 2,
    UpgradeCost: 3,
    UpgradeTime: 4,
    LandcoreLevel: 5,
  },

  BombardStatsList: {
    HP: 0,
    BonusDMG: 1,
    Slot: 2,
    UpgradeCost: 3,
    UpgradeTime: 4,
    LandcoreLevel: 5,
  },
};
export default Config;
