import axios from "axios";
import Config from "config";
import dayjs from "dayjs";

var saveData = (function () {
  var a: any = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data: Blob, fileName: any) {
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

async function post(url: string, data: any): Promise<any> {
  let rs = await fetch(`${Config.ApiHost}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });

  switch (rs.status) {
    case 200:
      if (data.export) {
        let data = await rs.blob();
        let now = dayjs().format("DD_MM_YYYY");
        saveData(data, `${now}.xlsx`);
        return rs;
      }
      let tmp = await rs.json();
      return tmp;
    case 403:
      window.location.href = "#/login";
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function auctionPost(url: string, data: any): Promise<any> {
  let rs = await fetch(`${Config.AuctionHost}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });

  switch (rs.status) {
    case 200:
      if (data.export) {
        let data = await rs.blob();
        let now = dayjs().format("DD_MM_YYYY");
        saveData(data, `${now}.xlsx`);
        return rs;
      }
      let tmp = await rs.json();
      return tmp;
    case 403:
      window.location.href = "#/login";
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function postFormData(
  url: string,
  data: any,
  cb?: Function
): Promise<any> {
  var formdata = new FormData();
  for (var i in data) {
    formdata.append(i, data[i]);
  }
  let tmp: any = await axios.request({
    method: "post",
    url: `${Config.ApiHost}${url}`,
    data: formdata,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "multipart/form-data",
    },
    onUploadProgress: (p) => {
      if (cb) cb(p);
      //this.setState({
      //fileprogress: p.loaded / p.total
      //})
    },
  });
  if (tmp.code === "forbidden") {
    window.location.href = "/auth";
    throw new Error("forbidden");
  }
  return tmp.data;
}
async function uploadImage(image: any): Promise<any> {
  return postFormData(`${Config.ApiHost}/file/upload-image`, {
    image,
  });
}
async function battlePost(url: any, data?: {}) {
  if (!data) {
    data = {};
  }
  let response = await fetch(`${Config.BattleHost}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `0JpKHDhzc42HdvF0OrRf`,
    },
    body: JSON.stringify(data),
  });
  let rs = await response.json();
  if (response.status !== 200) {
    throw rs;
  }
  return rs;
}

async function hackCreatePlot(
  wallet_address: any,
  plot_race: any,
  plot_rarity: any,
  plot_type: any,
  amount: any
) {
  return post("/cheat/create-plot", {
    wallet_address,
    plot_race,
    plot_rarity,
    plot_type,
    amount,
  });
}
async function hackCreateEgg(
  wallet_address: any,
  mongen_race: any,
  mongen_rarity: any,
  amount: any
) {
  return post("/cheat/create-mongen", {
    wallet_address,
    mongen_race,
    mongen_rarity,
    amount,
  });
}
async function hackSkiptime(address: any, plot_id: any, time: any) {
  return post("/cheat/skip-time", { address, plot_id, time });
}
async function hackDeleteCustomer(wallet_address: any) {
  return post("/cheat/delete-customer", { address: wallet_address });
}
async function hackCustomerTransaction(
  wallet_address: any,
  amount: any,
  type: any
) {
  return post("/cheat/customer-transaction", {
    address: wallet_address,
    amount,
    type,
  });
}
async function hackCreateTroops(troop_id: any, mongen_id: any, quantity: any) {
  return post("/cheat/create-troops", { troop_id, mongen_id, quantity });
}
async function hackTutorialProcess(wallet_address: any, process: any) {
  return post("/cheat/change-tutorial-process", {
    wallet_address,
    process,
  });
}
async function deleteName(wallet_address: any) {
  return post("/cheat/delete-customer-name", { wallet_address });
}
async function outBattle(wallet_address: any) {
  return post("/cheat/out-battle", { wallet_address });
}
async function recalculateStake() {
  return post("/cheat/recalculate-stake", {});
}
async function convertMongenToEgg(mongen_id: any) {
  return post("/cheat/convert-mongen-to-egg", { mongen_id });
}
async function cheatClaimLevelReward(wallet_address: any, level_reward: any) {
  return post("/cheat/claim-level-reward", {
    wallet_address,
    level_reward,
  });
}
async function cheatSetQuestTime(wallet_address: any, time: any) {
  return post("/cheat/set-quest-time", { wallet_address, time });
}
async function cheatSetFaucetTime(time: any) {
  return post("/cheat/set-faucet-time", { time });
}
async function addWhitelist(type: any, identity: any) {
  return post("/cheat/add-whitelist", { type, identity });
}
async function getPool() {
  return post("/cheat/get-box-pool", {});
}
async function cheatCreateBox(wallet_address: any, pool_id: any) {
  return post("/cheat/create-box", { wallet_address, pool_id });
}
async function cheatAdventureStar(wallet_address: any, value: any) {
  return post("/cheat/cheat-adventure_star", { wallet_address, value });
}
async function cheatAdventureStarReward(wallet_address: any, value: any) {
  return post("/cheat/cheat-adventure_star_reward", { wallet_address, value });
}
async function getMongenInfo(mongen_id: any) {
  return post("/cheat/get-mongen-info", { mongen_id });
}
async function updateTroopMongen(mongen_id: any, troops: any) {
  return post("/cheat/update-troop-mongen", { mongen_id, troops });
}
async function updateSkillMongen(mongen_id: any, skill_list: any, type: any) {
  return post("/cheat/update-skill-mongens", { mongen_id, skill_list, type });
}
async function hackgenerateInvest() {
  return post("/cheat/generate-invest", {});
}

const api = {
  post,
  postFormData,
  uploadImage,
  cheatAdventureStarReward,
  cheatAdventureStar,
  cheatCreateBox,
  addWhitelist,
  getPool,
  cheatSetFaucetTime,
  cheatSetQuestTime,
  cheatClaimLevelReward,
  convertMongenToEgg,
  battlePost,
  recalculateStake,
  hackCreatePlot,
  hackCreateEgg,
  hackSkiptime,
  hackDeleteCustomer,
  hackCustomerTransaction,
  hackCreateTroops,
  hackTutorialProcess,
  deleteName,
  getMongenInfo,
  updateTroopMongen,
  updateSkillMongen,
  hackgenerateInvest,
  outBattle,
  auctionPost,
};
export default api;
