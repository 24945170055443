import { useEffect, useMemo, useState } from "react";
import { initPlayer, queryImage } from "./player";

function getId() {
  if (!window.animationMonsterraId) {
    window.animationMonsterraId = 1;
  }
  return window.animationMonsterraId++;
}

function Mongen({ dna, }) {
  const [img, setImg] = useState(null);
  useEffect(() => {
    initPlayer();
  }, []);

  useMemo(() => {
    queryImage(dna, (img) => {
      setImg(img);
    });
    return () => { };
  }, [dna]);
  return img === null ? <p>Loading</p> : <img src={img} />;
}

function Soulcore({ dna }) {
  const [opacity, setOpacity] = useState(0);
  const [instance, setInstance] = useState(null);
  const playerId = useMemo(() => {
    return `monsterra-player-${getId()}`;
  });

  function setAnim() {
    if (!instance) {
      return;
    }
    setOpacity(100);
    instance.skeleton.setSkinByName(`Skin${dna[1][0] + 1}`);
    instance.setAnimation("Hold", true);
    instance.skeleton.setToSetupPose();
  }

  useEffect(() => {
    setAnim();
  }, [instance]);

  useEffect(() => {
    let race = dna[0][0];
    let raceNameMap = ["beast", "tectos", "mystic"];
    let inst = new window.spine.SpinePlayer(playerId, {
      premultipliedAlpha: true,
      animation: "Hold",
      alpha: true,
      showControls: false,
      backgroundColor: "#00000000",
      jsonUrl: `/soulcore/${raceNameMap[race]}/Egg_Tectos_1.json`,
      atlasUrl: `/soulcore/${raceNameMap[race]}/Egg_Tectos_1.atlas`,
      success: () => {
        setInstance(inst);
      },
      error: () => { },
    });
    return () => { };
  }, []);
  return <div></div>;
}
export default function StaticAnim({
  dna,
}) {
  if (dna.length === 2) {
    return <Soulcore dna={dna} />;
  } else {
    return (
      <Mongen
        dna={dna}
      />
    );
  }
}
